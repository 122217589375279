<template>
    <div class="">
        <head_Menu :parentmsg="3"></head_Menu>
        <div class="artdiv">
            <div class="artleft_top">
                <p class="arttitle animate__animated animate__fadeInRight animate__slow">
                    {{ $t('ArtFestival.msg1') }}</p>
                <p class="art_p animate__animated animate__fadeInRight animate__slow">{{ $t('ArtFestival.msg2') }}</p>
                <p class="MoreInfomation"><span @click="totalent">
                        {{ $t('ArtFestival.msg3') }}...
                    </span></p>
            </div>
            <div class="artright_top">
                <img src="../assets/static/29.png" alt="">
            </div>
        </div>
        <div class="artdiv">
            <div class="newimg1">
                <div class="newimg2"> <img src="../assets/static/31.png" alt=""></div>
                <div class="newimg2"> <img src="../assets/static/32.png" alt=""></div>
                <div class="newimg2"> <img src="../assets/static/33.png" alt=""></div>
            </div>
            <div class="newimg3">
                <p class="newimg4 animate__animated animate__fadeInRight animate__slow">
                    {{ $t('ArtFestival.msg4') }}</p>
                <p class="newimg5 animate__animated animate__fadeInRight animate__slow">{{ $t('ArtFestival.msg5') }}</p>
            </div>
        </div>
        <div class="artdiv mobileartdiv">
            <div class="artleft_top1">
                <p class="arttitle animate__animated animate__fadeInRight animate__slow">
                    {{ $t('ArtFestival.msg6') }}</p>
                <p class="art_p animate__animated animate__fadeInRight animate__slow">{{ $t('ArtFestival.msg7') }}</p>
                <p class="art_p animate__animated animate__fadeInRight animate__slow">{{ $t('ArtFestival.msg8') }}</p>
            </div>
            <div class="artright_top21">
                <img class="artright_top21_img" src="../assets/static/21.png" alt="">
                <img class="artright_top34_img" src="../assets/static/21.png" alt="">
            </div>


        </div>
        <!-- <div class="artdiv">
            <div class="artthree">
                <p class="artthreep1 animate__animated animate__fadeInRight animate__slow">Yue MinJun ‘Y’<br> International
                    Young<br> Talent Awards</p>
                <p class="artthreep animate__animated animate__fadeInRight animate__slow2">Organised by the Yue MinJun Art
                    Center, this is an international visual arts
                    competition that celebrates the creativity of exceptional young artists aged 11 to 24, supporting their
                    future artistic journeys and careers. The selected artworks will be exhibited at Ruthin School from June
                    to July 2024.</p>
            </div>
            <div class="artthree">
                <img src="../assets/static/22.png" alt="">
            </div>
            <div class="artthree">
                <p class="artthreep3 animate__animated animate__fadeInRight animate__slow">
                    Yue MinJun<br>
                    ‘Y’ International Young<br> Talent Awards</p>
                <p class="artthreep4 animate__animated animate__fadeInRight animate__slow">
                    Event Details<br>

                    xxx<br>

                    How to participate<br>

                    xxx<br>

                    Deadlines<br>

                    xxx
                </p>
            </div>
        </div>
        <div class="participate">
            <div class="participate_l">
                <p class="participate_p1">Yue MinJun</p>
                <p class="participate_p1">‘Y’ International Young Talent Awards</p>
                <p class="participate_p6">How to participate?</p>
                <p class="participate_p2">1.Create your account</p>
                <p class="participate_p2">2.Start creating your entry (You can modify it until the application deadline).
                </p>
                <p class="participate_p2">3.Submit your entry to be pre-selected as a finalist.</p>
                <p class="participate_p2">4.Finalist selection announcement on the 9th of April</p>
                <p class="participate_p3">**Make sure to consult guidelines before competing!</p>
                <p class="participate_p4">Further Guidelines</p>
                <p class="participate_p5">For any questions, please contact</p>
                <p class="participate_p5">advisors@hospitality-on.com.</p>
            </div>
            <div class="participate_r">
                <div class="participate_rdiv">
                    <p class="participate_rp1">Log in or register</p>
                    <p class="participate_rp2">The "Y" International Young Talent Awards is a prestigious international
                        competition honoring outstanding
                        student-led vision of the Art industry</p>
                    <div class="participate_rp3">
                        <div class="participate_rp4">
                            <p class="participate_rp5">名字</p>
                            <input class="participate_rp6" type="text">
                        </div>
                        <div class="participate_rp4">
                            <p class="participate_rp5">姓氏</p>
                            <input class="participate_rp6" type="text">
                        </div>
                    </div>
                    <div class="participate_rp7">
                        <p class="participate_rp5">電子信箱*</p>
                        <input class="participate_rp6" type="text">
                    </div>
                    <div class="participate_rp7">
                        <p class="participate_rp5">地址*</p>
                        <input class="participate_rp6" type="text">
                    </div>
                    <div class="participate_rp9">
                        <input class="participate_rp8" type="checkbox" id="checkbox03" />
                        <label for="checkbox03">我接受條款與條件</label>

                    </div>
                    <div class="participate_rp9">
                        <button class="Register">Register</button>
                    </div>
                </div>

            </div>
        </div>
        <div class="allbottom334">
            <div class="bottom334">
                <div class="bottom334_div1">Artistic<br>Journey</div>
                <div class="bottom334_div2">
                    <img class="bottom334_div3" src="../assets/static/24.png" alt="">
                </div>
                <div class="bottom334_div4">
                    Students can immerse themselves in the learning environment of a top British school, gathering with
                    peers from around the world for a shared experience in learning and living, honing their English skills,
                    and enhancing their artistic literacy.

                    Our art courses are designed by leading artists and experienced teachers.</div>
            </div>
            <div class="bottom334">
                <div class="bottom334_div4">

                    The students will be mentored by artists and they will develop and create their own work for an
                    exhibition at Ruthin’s Art Centre, named after the prestigious Chinese artist Yue Minjun.</div>
                <div class="bottom334_div5">Exhibition</div>
                <div class="bottom334_div2">
                    <img class="bottom334_div3" src="../assets/static/25.png" alt="">
                </div>

            </div>
        </div> -->
        <Footpage :foot="1"></Footpage>
    </div>
</template>
<script>
export default {
    name: 'HeadMenu',
    data() {
        return {
            msg: '头部'
        };
    },
    methods: {
        Changetab(item) {
            this.$router.push({
                path: item
            })
        },
        totalent() {
            this.$router.push({
                path: '/TalentAward'
            })
        }
    }
}
</script>
<style scoped>
.MoreInfomation {
    font-size: 17px;
    font-weight: bold;
    margin-top: 40px;
    /* display: inline-block; */
    text-align: left;

}

.MoreInfomation span {
    display: inline-block;
    cursor: pointer;
    transition: transform .2s;
    /* border: 1px solid #000; */

}

.MoreInfomation span:hover {
    transform: scale(1.1);
    background: #fff;
    height: 40px;
    box-sizing: border-box;
    padding: 0 10px;
    line-height: 40px;
    letter-spacing: 1px;
    color: rgb(129, 150, 153);
}

.artdiv {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 30px;
}

.artleft_top,
.artright_top,
.artleft_top1 {
    width: 50%;
}

.artright_top {
    height: 395px;
}

.artleft_top {
    background: radial-gradient(circle at 3.6751302083333335% 6.940104166666666%, #4D6001 0%, 11.55%, rgba(77, 96, 1, 0) 35%), radial-gradient(circle at 92.96712239583333% 9.554036458333332%, #FAAA63 0%, 17.5%, rgba(250, 170, 99, 0) 35%), radial-gradient(circle at 95.20670572916666% 90.48177083333333%, #C9C6FF 0%, 34.1%, rgba(201, 198, 255, 0) 55%), radial-gradient(circle at 4.313151041666666% 93.34635416666667%, #97B49D 0%, 29.900000000000002%, rgba(151, 180, 157, 0) 65%), radial-gradient(circle at 46.555989583333336% 11.50390625%, rgba(214, 222, 72, 0.99) 0%, 25%, rgba(214, 222, 72, 0) 50%), radial-gradient(circle at 51.121419270833336% 89.98697916666667%, #93C3FF 0%, 42%, rgba(147, 195, 255, 0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255, 255, 255, 0) 100%);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    /* justify-content: center; */
    box-sizing: border-box;
    padding: 50px;
}

.artleft_top1 {
    background: radial-gradient(circle at 3.6751302083333335% 6.940104166666666%, #EDD292 0%, 11.55%, rgba(237, 210, 146, 0) 35%), radial-gradient(circle at 92.96712239583333% 9.554036458333332%, #EDD292 0%, 17.5%, rgba(237, 210, 146, 0) 35%), radial-gradient(circle at 95.20670572916666% 90.48177083333333%, #CCE0F2 0%, 34.1%, rgba(204, 224, 242, 0) 55%), radial-gradient(circle at 4.313151041666666% 93.34635416666667%, #97B49D 0%, 29.900000000000002%, rgba(151, 180, 157, 0) 65%), radial-gradient(circle at 46.555989583333336% 11.50390625%, rgba(228, 224, 217, 0.99) 0%, 25%, rgba(228, 224, 217, 0) 50%), radial-gradient(circle at 51.121419270833336% 89.98697916666667%, #93C3FF 0%, 42%, rgba(147, 195, 255, 0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255, 255, 255, 0) 100%);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 50px;
    min-height: 532px;
}

.artright_top21 {
    width: 50%;
    min-height: 532px;
}

.artleft_top p {
    width: 100%;
    text-align: left;
}

.artright_top img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.arttitle {
    color: #232A75;
    font-size: 37px;
    width: 100%;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
    word-break: break-word;
}

.art_p {
    font-size: 17px;
    color: #000;
    line-height: 32px;
    font-weight: 500;
    word-break: break-word;
    text-align: left;
}

.artthree {
    width: 33.33%;
    background: radial-gradient(circle at 3.6751302083333335% 6.940104166666666%, #C0CBCC 0%, 11.55%, rgba(192, 203, 204, 0) 35%), radial-gradient(circle at 92.96712239583333% 9.554036458333332%, #FAAA63 0%, 17.5%, rgba(250, 170, 99, 0) 35%), radial-gradient(circle at 95.20670572916666% 90.48177083333333%, #C9C6FF 0%, 34.1%, rgba(201, 198, 255, 0) 55%), radial-gradient(circle at 4.313151041666666% 93.34635416666667%, #FFFFFF 0%, 29.900000000000002%, rgba(255, 255, 255, 0) 65%), radial-gradient(circle at 46.555989583333336% 11.50390625%, rgba(214, 222, 72, 0.99) 0%, 25%, rgba(214, 222, 72, 0) 50%), radial-gradient(circle at 51.121419270833336% 89.98697916666667%, #93C3FF 0%, 42%, rgba(147, 195, 255, 0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255, 255, 255, 0) 100%);
}

.artthree img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.artthreep1 {
    box-sizing: border-box;
    padding: 20px 40px;
    text-align: left;
    font-size: 32px;
    color: #232A75;
    word-break: break-word;
    font-weight: bold;
}

.artthreep2 {
    box-sizing: border-box;
    padding: 20px 40px;
    text-align: left;
    font-size: 17px;
    color: #000;
    word-break: break-word;
    line-height: 32px;
}

.artthreep4 {
    box-sizing: border-box;
    padding: 20px 40px;
    text-align: center;
    font-size: 17px;
    color: #000;
    word-break: break-word;
    line-height: 32px;
}

.artthreep3 {
    box-sizing: border-box;
    padding: 20px 40px;
    text-align: left;
    font-size: 32px;
    color: #232A75;
    font-weight: 500;
}

.participate {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.participate_l,
.participate_r {
    width: 50%;
}

.participate_l {
    background: radial-gradient(circle at 3.6751302083333335% 6.940104166666666%, #FFFFFF 0%, 11.55%, rgba(255, 255, 255, 0) 35%), radial-gradient(circle at 92.96712239583333% 9.554036458333332%, #819699 0%, 17.5%, rgba(129, 150, 153, 0) 35%), radial-gradient(circle at 95.20670572916666% 90.48177083333333%, #FFD2C4 0%, 34.1%, rgba(255, 210, 196, 0) 55%), radial-gradient(circle at 4.313151041666666% 93.34635416666667%, #E0E5E6 0%, 29.900000000000002%, rgba(224, 229, 230, 0) 65%), radial-gradient(circle at 46.555989583333336% 11.50390625%, rgba(228, 224, 217, 0.99) 0%, 25%, rgba(228, 224, 217, 0) 50%), radial-gradient(circle at 51.121419270833336% 89.98697916666667%, #93C3FF 0%, 42%, rgba(147, 195, 255, 0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255, 255, 255, 0) 100%);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    box-sizing: border-box;
    padding: 30px 0;
}

.participate_l p {
    width: 454px;
}

.participate_p1 {
    font-size: 25px;
    color: #232A75;
    font-weight: 500;
}

.participate_p6 {
    font-size: 40px;
    color: #000;
    font-weight: bold;
    margin-bottom: 30px;
}

.participate_p2 {
    font-size: 19px;
    color: #000;
    line-height: 30px;
    font-weight: lighter;
}

.participate_p3 {
    font-size: 16px;
    color: #000;
    margin-top: 30px;
    line-height: 40px;
}

.participate_p4 {
    font-size: 16px;
    color: #000;
    ;
    line-height: 40px;
    text-decoration: underline;
    margin-bottom: 50px;
}

.participate_p5 {
    font-size: 20px;
    color: #000;
    line-height: 40px;
}

.participate {
    display: flex;
    flex-wrap: wrap;
}

.participate_rdiv {
    width: 415px;

}

.participate_r {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    box-sizing: border-box;
    padding: 30px 0;
}

.participate_rp1 {
    font-size: 35px;
    font-weight: bold;
    color: #000;
}

.participate_rp2 {
    width: 261px;
    font-size: 19px;
    color: #000;
    line-height: 30px;
    word-break: break-word;
}

.participate_rp3 {
    display: flex;
    justify-content: space-between;
    width: 284px;
    margin-bottom: 15px;
}

.participate_rp4 {
    width: 48%;
    border-bottom: 2px solid #000;
}

.participate_rp7 {
    width: 284px;
    border-bottom: 2px solid #000;
    margin-bottom: 15px;
}

.participate_rp5 {
    font-size: 12px;
    color: #000;
}

.participate_rp6 {
    height: 36px;
    line-height: 36px;
    border: none;
    outline: none;
}

.participate_rp8 {
    margin-right: 10px;
}

.participate_rp9 {
    margin-bottom: 15px;
}

.Register {
    width: 284px;
    height: 48px;
    line-height: 48px;
    background: #fff;
    border: 2px solid #000;
    border-radius: 40px;
    transition: background-color 0.3s ease;
    /* transition: height .5s ease-in-out; */
}

.Register:hover {
    background: #000;
    color: #fff;

}

.bottom334 {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

}

.bottom334_div1,
.bottom334_div2 {
    width: 30%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.bottom334_div1 {
    background: #a7b29f;
    color: #fff;
    font-size: 42px;
    font-weight: bold;
}

.bottom334_div5 {
    width: 30%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: rgb(129, 150, 153);
    color: #fff;
    font-size: 42px;
    font-weight: bold;
}

.bottom334_div4 {
    width: 40%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: #edd292;
    font-size: 17px;
    line-height: 32px;
    box-sizing: border-box;
    padding: 0 40px;
    text-align: left;
    word-break: break-word;
}

.bottom334_div3 {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.allbottom334 {
    margin-bottom: 30px;
}

.newimg1,
.newimg3 {
    width: 50%;
}

.newimg1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: flex-end;
}

.newimg2 {
    width: 33.333333333%;
}

.newimg2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.newimg3 {
    background: radial-gradient(circle at 3.6751302083333335% 6.940104166666666%, #4D6001 0%, 11.55%, rgba(77, 96, 1, 0) 35%), radial-gradient(circle at 92.96712239583333% 9.554036458333332%, #FAAA63 0%, 17.5%, rgba(250, 170, 99, 0) 35%), radial-gradient(circle at 95.20670572916666% 90.48177083333333%, #C9C6FF 0%, 34.1%, rgba(201, 198, 255, 0) 55%), radial-gradient(circle at 4.313151041666666% 93.34635416666667%, #97B49D 0%, 29.900000000000002%, rgba(151, 180, 157, 0) 65%), radial-gradient(circle at 46.555989583333336% 11.50390625%, rgba(214, 222, 72, 0.99) 0%, 25%, rgba(214, 222, 72, 0) 50%), radial-gradient(circle at 51.121419270833336% 89.98697916666667%, #93C3FF 0%, 42%, rgba(147, 195, 255, 0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255, 255, 255, 0) 100%);
    box-sizing: border-box;
    box-sizing: border-box;
    padding: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.newimg4 {
    width: 100%;
    font-weight: bold;
    font-size: 37px;
    color: #232A75;
    word-break: break-word;
    text-align: left;
    margin-bottom: 15px;
}

.newimg5 {
    font-size: 17px;
    line-height: 32px;
    word-break: break-word;
    text-align: left;

}

.artright_top21_img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.artright_top34_img {
    display: none;
}

/* 手机屏幕的字体大小 */
@media screen and (max-width: 900px) {
    .artdiv {
        width: 100%;
    }

    .artleft_top,
    .artright_top,
    .artleft_top1,
    .artright_top21 {
        width: 100%;
        height: auto;
    }

    .artthree {
        width: 100%;
    }

    .participate_l,
    .participate_r {
        width: 100%;
    }

    .participate_l p {
        width: 90%;
    }

    .participate_rdiv {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .participate_p1 {
        font-size: 20px;
    }

    .participate_p6 {
        font-size: 30px;
    }

    .bottom334_div1,
    .bottom334_div2,
    .bottom334_div4,
    .bottom334_div5 {
        width: 100%;
    }

    .bottom334 {
        width: 100%;
    }

    .newimg1,
    .newimg2,
    .newimg3 {
        width: 100%;
    }

    .newimg2 {
        margin-bottom: 10px;
    }

    .newimg2 img {
        width: auto;

    }

    .newimg1 {
        box-sizing: border-box;
        padding-top: 10px;
    }

    .newimg4 {
        margin-top: 20px;
        font-size: 26px;
    }

    .artdiv {
        margin-bottom: 0;
    }

    .arttitle {
        font-size: 27px;

    }

    .artright_top21_img {
        display: none;
    }

    .artright_top34_img {
        display: block;
        width: 100%;
    }

    .mobileartdiv {
        flex-direction: column-reverse;
    }
}
</style>