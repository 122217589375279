<template>
    <div class="about">
        <head_Menu :parentmsg="5"></head_Menu>
        <div class="contactcon3">

            <div class="contanctdiv">
                <div class="registrationdiv">
                    <p class="editp1">{{ $t("SubmitWork.msg1") }}</p>
                    <p class="editp2">
                        {{ $t("SubmitWork.msg2").replace("xxx", info.email) }} <br />
                        <span @click="logout">{{ $t("SubmitWork.msg3") }}</span>

                    </p>
                </div>
                <div class="Registration_pp1">
                    <div class="Registrationcenterdiv">
                        <p class="Registration_p2">
                            {{ $t("SubmitWork.msg4") }}
                        </p>
                        <div class="cutdowndiv " v-show="false">
                            <count-down v-on:start_callback="countDownS_cb(1)" v-on:end_callback="countDownE_cb(1)"
                                :currentTime=Number(startTime) :startTime=Number(startTime) :endTime=Number(endTime)
                                :endText="'结束自定义文字2'" :dayTxt="'Days'" :hourTxt="'Hours'" :minutesTxt="'Minutes'"
                                :secondsTxt="'Seconds'"></count-down>
                        </div>
                        <div class="detailsipt">
                            <div>
                                <p>{{ $t("SubmitWork.msg5") }}</p>
                                <input :disabled="draftFlag" class="iptdiv" v-model="studentName" type="text" />
                            </div>
                            <div>
                                <p>{{ $t("SubmitWork.msg6") }}</p>
                                <input :disabled="draftFlag" class="iptdiv" v-model="grade" type="text" />
                            </div>
                        </div>
                        <div class="detailsipt">
                            <div>
                                <p>{{ $t("SubmitWork.msg7") }}</p>
                                <input :disabled="draftFlag" class="iptdiv" v-model="studentAge" type="text" />
                            </div>
                        </div>
                        <div class="detailsipt">
                            <div>
                                <p>
                                    <span class="smalltitle">{{ $t("SubmitWork.msg8") }}</span>
                                    <span class="sstitle">{{ $t("SubmitWork.msg9") }}</span>
                                </p>
                                <input :disabled="draftFlag" class="iptdiv Artwork" v-model="artName" type="text" />
                            </div>
                        </div>
                        <div class="detailsipt">
                            <div>
                                <p>
                                    <span class="smalltitle">{{ $t("SubmitWork.msg10") }} </span>
                                    <span class="sstitle">{{ $t("SubmitWork.msg11") }}</span>
                                </p>
                                <input :disabled="draftFlag" class="iptdiv Artwork" v-model="artDescription" type="text" />
                            </div>
                        </div>
                        <div class="detailsipt">
                            <div>
                                <p>
                                    <span class="smalltitle">{{ $t("SubmitWork.msg12") }}</span>
                                </p>
                                <el-checkbox-group :disabled="draftFlag" v-model="artType"
                                    @change="handleCheckedCitiesChange">
                                    <!-- <el-checkbox v-for="city in cities" :label="city" :key="city">{{ city }}</el-checkbox> -->
                                    <el-checkbox label="1">{{
                                        $t("SubmitWork.msg35")
                                    }}</el-checkbox>
                                    <el-checkbox label="2">{{
                                        $t("SubmitWork.msg36")
                                    }}</el-checkbox>
                                    <el-checkbox label="3">{{
                                        $t("SubmitWork.msg37")
                                    }}</el-checkbox>
                                    <el-checkbox label="4">{{
                                        $t("SubmitWork.msg38")
                                    }}</el-checkbox>
                                    <el-checkbox label="5">{{
                                        $t("SubmitWork.msg39")
                                    }}</el-checkbox>
                                    <el-checkbox label="6">{{
                                        $t("SubmitWork.msg40")
                                    }}</el-checkbox>
                                    <el-checkbox label="7">{{
                                        $t("SubmitWork.msg41")
                                    }}</el-checkbox>
                                    <el-checkbox label="8">{{
                                        $t("SubmitWork.msg42")
                                    }}</el-checkbox>
                                    <el-checkbox label="9">{{
                                        $t("SubmitWork.msg43")
                                    }}</el-checkbox>
                                    <el-checkbox label="10">{{
                                        $t("SubmitWork.msg44")
                                    }}</el-checkbox>
                                    <el-checkbox label="11">{{
                                        $t("SubmitWork.msg45")
                                    }}</el-checkbox>
                                    <el-checkbox label="12">{{
                                        $t("SubmitWork.msg46")
                                    }}</el-checkbox>
                                    <el-checkbox label="13">{{
                                        $t("SubmitWork.msg47")
                                    }}</el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </div>
                        <div class="detailsipt">
                            <div>
                                <p>
                                    <span class="smalltitle">{{ $t("SubmitWork.msg13") }}</span><br />
                                    <span class="sstitle">{{ $t("SubmitWork.msg14") }}</span>
                                </p>
                                <input :disabled="draftFlag" class="iptdiv" v-model="specialRemark" type="text" />
                            </div>
                        </div>
                        <div class="detailsipt">
                            <div>
                                <p>
                                    <span class="smalltitle">{{ $t("SubmitWork.msg15") }}</span>
                                </p>
                                <div class="liptdivdiv">
                                    <span class="valspan">{{ $t("forgot.msg16") }}：</span>
                                    <input :disabled="draftFlag" class="liptdiv" v-model="lengthval" type="text" />
                                    <span>cm</span>
                                </div>
                                <div class="liptdivdiv">
                                    <span class="valspan">{{ $t("forgot.msg17") }}：</span>
                                    <input :disabled="draftFlag" class="liptdiv" v-model="widthval" type="text" />
                                    <span>cm</span>
                                </div>
                                <div class="liptdivdiv">
                                    <span class="valspan">{{ $t("forgot.msg18") }}：</span>
                                    <input :disabled="draftFlag" class="liptdiv" v-model="heightval" type="text" />
                                    <span>cm</span>
                                </div>
                                <!-- <p>
                                    <span class="smalltitle">{{ $t("SubmitWork.msg15") }}234</span>
                                </p>
                                <el-select v-model="size" :placeholder="$t('SubmitWork.msg15')">
                                    <el-option :label="$t('SubmitWork.msg30')" value="1"></el-option>
                                    <el-option :label="$t('SubmitWork.msg31')" value="2"></el-option>
                                    <el-option :label="$t('SubmitWork.msg32')" value="3"></el-option>
                                    <el-option :label="$t('SubmitWork.msg33')" value="4"></el-option>
                                    <el-option :label="$t('SubmitWork.msg34')" value="5"></el-option>
                                </el-select> -->
                            </div>
                        </div>
                        <p class="Registration_p2">
                            {{ $t("SubmitWork.msg16") }}
                        </p>
                        <div class="Uploaddiv">
                            <span class="Documentspan">{{ $t("SubmitWork.msg29") }}</span>
                            <el-upload :disabled="draftFlag" class="upload-demo" ref="fileUploadBtn"
                                accept="image/jpg,image/jpeg,image/png" :action="basurl + '/art/file/upload'"
                                :headers="headers" :limit="1" :show-file-list="false" :on-remove="handleRemove"
                                :on-success="handleSuccess_e" :on-change="licenseimgChange"
                                :on-progress="uploadVideoProcess" :before-upload="beforeAvatarUpload">
                                <span class="short_p66">{{
                                    $t("SubmitWork.msg17")
                                }}</span><el-progress v-if="progressFlag"
                                    :percentage="loadProgress"></el-progress></el-upload>
                            <!-- 进度条 -->

                        </div>
                        <div v-if="annex" class="oneimg">
                            <el-image style="width: 100px; height: 100px" fit="scale-down" :src="annex"
                                :preview-src-list="srcList">
                            </el-image>
                            <div class="imgcaozuo1">
                                <i class="el-icon-zoom-in" @click="checkoneimg()"></i>
                                <i class="el-icon-delete" @click="deloneimg()"></i>
                            </div>
                        </div>
                        <div class="Uploaddiv">
                            <span class="Documentspan">{{ $t("SubmitWork.msg18") }}</span>
                            <el-upload :disabled="draftFlag" v-if="fileList.length < 3" class="upload-demo"
                                accept="image/jpg,image/jpeg,image/png" ref="fileUploadBtn1"
                                :action="basurl + '/art/file/upload'" :headers="headers" :limit="3" :show-file-list="false"
                                :on-remove="handleRemove1" :on-success="handleSuccess1" :on-change="licenseimgChange1"
                                :on-progress="uploadVideoProcess1" :before-upload="beforeAvatarUpload">
                                <span class="short_p66">{{ $t("SubmitWork.msg17") }}</span>
                                <el-progress v-if="progressFlag1" :percentage="loadProgress1"></el-progress>
                            </el-upload>
                            <span v-else class="short_p66">{{ $t("SubmitWork.msg17") }}</span>
                        </div>

                        <!-- 备注图片 -->
                        <div v-if="pictures">
                            <div class="artimgdiv" v-for="(item, index) in fileList" :key="index">
                                <el-image class="artimg" :src="item" fit="scale-down"> </el-image>
                                <div class="imgcaozuo">
                                    <i class="el-icon-zoom-in" @click="checkbigimg(index)"></i>
                                    <i class="el-icon-delete" @click="delimg(index)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="subtext">
                            <p>{{ $t("forgot.msg11") }}</p>
                        </div>
                        <div class="Uploaddiv">
                            <span class="Documentspan">{{ $t("SubmitWork.msg19") }}</span>
                            <input :disabled="draftFlag" class="linkipt" type="text" v-model="link"
                                :placeholder="$t('SubmitWork.msg20')" />
                        </div>
                        <div class="subtext">
                            <p>{{ $t("SubmitWork.msg21") }}</p>
                            <p>{{ $t("SubmitWork.msg22") }}</p>
                            <p>
                                <span>{{ $t("SubmitWork.msg23") }}<br />
                                    &nbsp; &nbsp; {{ $t("SubmitWork.msg24") }}<br />
                                    &nbsp; &nbsp; {{ $t("SubmitWork.msg25") }}</span>
                            </p>
                            <p class="imp">
                                {{ $t("SubmitWork.msg54") }}
                            </p>
                        </div>
                        <div class="threebut">
                            <span class="short_p77" @click="goback">{{
                                $t("SubmitWork.msg26")
                            }}</span>
                            <!-- <span v-if="!draftFlag" class="short_p66" @click="submit(0)">{{
                                $t("SubmitWork.msg27")
                            }}</span> -->
                            <span v-if="!draftFlag" class="short_p66" @click="submit">{{
                                $t("SubmitWork.msg28")
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footpage :foot="1"></Footpage>
        <!-- 查看大图 -->
        <!-- <el-dialog class="dwidh" title="" :visible.sync="dialogVisible" :before-close="handleClose">
            <img class="postimg" :src="bigimg" alt="">
        </el-dialog> -->
        <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="bigfileList" />
        <el-image-viewer v-if="showViewer1" :on-close="closeViewer1" :url-list="srcList" />
    </div>
</template>
<script>
// artadd
import CountDown from '../components/countdown'

import { artadd, userDetail, artinfo } from "@/api/index";
import { Message } from "element-ui";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
    name: "UploadDetails",
    components: {
        ElImageViewer, //注册
        CountDown
    },
    data() {
        return {
            startTime: '',
            endTime: '',
            loadProgress: 0, // 动态显示进度条
            progressFlag: false, // 关闭进度条
            loadProgress1: 0, // 动态显示进度条
            progressFlag1: false, // 关闭进度条
            // basurl: 'http://25455892qq.oicp.vip',
            basurl: 'https://ruthineducation.co.uk/api',
            bigimg: "",
            dialogVisible: false,
            showViewer: false,
            headers: {
                Authorization: localStorage.getItem("token"),
            },
            size: "",
            studentName: "",
            grade: "",
            studentAge: "",
            artName: "",
            artType: [],
            artDescription: "",
            link: "",
            specialRemark: "",
            annex: "",
            srcList: [],
            pictures: "",
            fileList: [],
            bigfileList: [],
            info: {},
            draftFlag: false,
            artId: '',
            imgs: [],
            flag: 0,
            showViewer1: false,
            lengthval: '',
            widthval: '',
            heightval: '',

        };
    },
    mounted() {
        this.gettime()
    },
    created() {
        // var datetime = "2024-04-12 12:00:00";
        // var timestamp = new Date(datetime).getTime() - 28800000;
        // console.log(1, timestamp);
        // this.startTime = currentTime.getTime()
        // var date = new Date('2024-04-12 12:00:00'); // 将 '2021-09-30' 替换成需要获取时间戳的日期字符串
        // // 调用 getTime() 方法获取该日期的时间戳（单位为毫秒）
        // var endTime = date.getTime() - 28800000;//跟英国相差8小时
        // console.log(2, endTime)
        // var timestamp = new Date().getTime();
        this.getuserDetail();
        // this.getartinfo();
    },
    methods: {
        gettime() {
            // 创建一个新的Date对象
            var currentTime = new Date();
            // 将Date对象转换为时间戳（单位为毫秒）
            this.startTime = currentTime.getTime()
            var date = new Date('2024-05-12 19:00:00'); // 将 '2021-09-30' 替换成需要获取时间戳的日期字符串
            // 调用 getTime() 方法获取该日期的时间戳（单位为毫秒）
            this.endTime = date.getTime();//跟英国相差8小时
        },
        countDownS_cb: function (x) {
            console.log(x)
        },
        countDownE_cb: function (x) {
            console.log(x)
            this.draftFlag = true
        },
        uploadVideoProcess(event, file, fileList) {
            this.progressFlag = true; // 显示进度条
            this.loadProgress = parseInt(event.percent); // 动态获取文件上传进度
            if (this.loadProgress >= 100) {
                this.loadProgress = 100
                setTimeout(() => { this.progressFlag = false }, 1000) // 一秒后关闭进度条
            }
        },
        uploadVideoProcess1(event, file, fileList) {
            this.progressFlag1 = true; // 显示进度条
            this.loadProgress1 = parseInt(event.percent); // 动态获取文件上传进度
            if (this.loadProgress1 >= 100) {
                this.loadProgress1 = 100
                setTimeout(() => { this.progressFlag1 = false }, 1000) // 一秒后关闭进度条
            }
        },
        beforeAvatarUpload(file) {
            const isLt5M = file.size / 1024 / 1024 < 5
            if (!isLt5M) {
                this.$message.error(this.$t("forgot.msg11"))
                return false
            }
        },
        logout() {
            // localStorage.removeItem('username')
            // localStorage.removeItem('password')
            localStorage.removeItem('token')
            this.$router.push({
                path: '/login'
            })
        },
        // 获取详情
        getartinfo(id) {
            let that = this;
            artinfo(id).then((res) => {
                const arr = res.data.data.size.split(',')
                console.log(arr)
                that.lengthval = arr[0]
                that.widthval = arr[1]
                that.heightval = arr[2]
                // that.lengthval + ',' + that.widthval + ',' + that.heightval,
                // that.draftFlag = res.data.data.draftFlag == 1 ? true : false
                that.flag = res.data.data.draftFlag
                that.studentName = res.data.data.studentName
                that.grade = res.data.data.grade
                that.studentAge = res.data.data.studentAge
                that.artName = res.data.data.artName
                that.artType = res.data.data.artType.split(',')
                that.specialRemark = res.data.data.specialRemark
                that.artDescription = res.data.data.artDescription
                // that.size = res.data.data.size
                that.annex = res.data.data.annex
                that.link = res.data.data.link
                that.srcList = [res.data.data.annex]
                that.pictures = res.data.data.pictures
                that.fileList = res.data.data.pictures.split(',')
                that.bigfileList = res.data.data.pictures.split(',')
            });
        },
        // 删除图片
        delimg(idx) {
            this.fileList.splice(idx, 1);
            this.bigfileList.splice(idx, 1);
            console.log(this.fileList)
        },
        deloneimg() {
            this.srcList = [],
                this.annex = ''
        },
        checkoneimg() {
            // this.srcList.unshift(...this.bigfileList.splice(idx));
            this.showViewer1 = true;
        },
        checkbigimg(idx) {
            this.bigfileList.unshift(...this.bigfileList.splice(idx));
            this.showViewer = true;
            // this.bigimg = url
        },
        // handleClose() {
        //     this.dialogVisible = false
        // },
        closeViewer() {
            this.bigfileList = JSON.parse(JSON.stringify(this.fileList))
            this.showViewer = false;
        },
        closeViewer1() {
            this.showViewer1 = false;
        },
        Changetab(item) {
            this.$router.push({
                path: item,
            });
        },
        goback() {
            this.$router.go(-1);
        },
        handleCheckedCitiesChange(val) {
            console.log(val);
        },
        handleRemove(fileList) { },
        handleSuccess_e(file) {
            console.log(file);
            if (file.code == 200) {
                this.srcList = [file.url];
                this.annex = file.url;
                Message({
                    message: this.$t("forgot.msg9"),
                    type: "success",
                    center: true,
                });
                this.$refs.fileUploadBtn.clearFiles(); //上传成功之后清除历史记录
            } else {
                Message({
                    message: file.msg,
                    type: "error",
                    center: true,
                });
                this.$refs.fileUploadBtn.clearFiles(); //上传成功之后清除历史记录
            }
        },
        handleCheckedCitiesChange1(val) {
            console.log(val);
        },
        handleRemove1(fileList) { },
        handleSuccess1(file) {
            console.log(file);
            if (file.code == 200) {
                let arr = [];
                arr.push(file.url);
                this.fileList.push(file.url);
                this.bigfileList.push(file.url);
                this.pictures = arr.join(",");
                console.log(this.fileList);
                Message({
                    message: this.$t("forgot.msg9"),
                    type: "success",
                    center: true,
                });
                this.$refs.fileUploadBtn1.clearFiles(); //上传成功之后清除历史记录
            } else {
                Message({
                    message: file.msg,
                    type: "error",
                    center: true,
                });
                this.$refs.fileUploadBtn1.clearFiles(); //上传成功之后清除历史记录
            }
        },
        licenseimgChange1() { },
        licenseimgChange() { },

        getuserDetail() {
            userDetail().then((response) => {
                this.info = response.data.data;
                if (response.data.data.artId) {
                    this.artId = response.data.data.artId
                    this.getartinfo(response.data.data.artId)
                }
            });
        },
        // 提交
        submit(flag) {
            let msg1 = this.$t("SubmitWork.msg48");
            let msg2 = this.$t("SubmitWork.msg49");
            let msg3 = this.$t("SubmitWork.msg50");
            let msg4 = this.$t("SubmitWork.msg51");
            let msg5 = this.$t("SubmitWork.msg53");

            // console.log(msg)
            let that = this;
            if (!that.artName) {
                this.$message({
                    type: 'error',
                    message: msg5
                });
                return;
            }
            // lengthval: '',
            // widthval: '',
            // heightval: ''
            const data = {
                studentName: that.studentName,
                grade: that.grade,
                studentAge: that.studentAge,
                artName: that.artName,
                artDescription: that.artDescription,
                artType: that.artType.join(","),
                specialRemark: that.specialRemark,
                size: that.lengthval + ',' + that.widthval + ',' + that.heightval,
                link: that.link,
                draftFlag: that.flag,
                annex: that.annex,
                pictures: that.fileList.join(","),
                artId: this.artId ? this.artId : undefined
                // size:that.size,
            };
            artadd(data).then((res) => {
                if (res.data.code == 200) {
                    if (this.artId) {
                        this.goback()
                        this.getartinfo(this.artId)
                    } else {
                        this.goback()
                    }
                    this.$message({
                        type: 'success',
                        message: 'success'
                    });
                }
            });
            // if (flag == 1) {
            //     this.$confirm(msg1, msg4, {
            //         confirmButtonText: msg2,
            //         cancelButtonText: msg3,
            //         type: "warning",
            //     })
            //         .then(() => {
            //             artadd(data).then((res) => { this.getuserDetail() });
            //         })
            //         .catch(() => { });
            // } else {

            // }
        },
    },
};
</script>
<style scoped>
.imp {
    font-weight: bold;
}

.cutdowndiv {
    width: 560px;
    margin: 30px auto;
    text-align: center;
}

.liptdivdiv {
    display: flex;
    margin-bottom: 10px;
}

.oneimg {
    width: 100px;
    height: 100px;
    position: relative;
}

.oneimg:hover .imgcaozuo1 {
    display: block;
}

.imgcaozuo1 {
    position: absolute;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    top: 0;
    left: 0;
    background-color: rgb(120, 120, 120, 0.6);
    display: none;
}

.imgcaozuo1 i {
    margin: 0 5px;
}

.el-dialog {
    width: 30%;
}

.editp2 span {
    cursor: pointer;
}

.el-upload--picture-card {
    background-color: #fff !important;
    border: none !important;
    border-radius: 6px;
    box-sizing: border-box;
    width: auto !important;
    height: 148px;
    cursor: pointer;
    line-height: 146px;
    vertical-align: top;
}

.artimgdiv {
    display: inline-block;
    position: relative;
}

.artimgdiv:hover .imgcaozuo {
    display: block;
}

.artimgdiv i {
    margin: 0 5px;
}

.imgcaozuo {
    position: absolute;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    top: 0;
    left: 0;
    background-color: rgb(120, 120, 120, 0.6);
    display: none;
}

.artimg {
    width: 100px;
    height: 100px;
    margin-right: 15px;
}

.about {
    background: rgb(95, 182, 199);
}

.contactcon3 {
    width: 90%;
    /* min-height: 1780px; */
    margin: 0 auto;
    text-align: left;
    margin-top: 20px;
}

.contanctdiv {
    position: relative;
    width: 100%;
    height: 100%;
    /* padding-top: 80px; */
}

.registrationdiv {
    width: 65%;
    height: 300px;
    background: url(../assets/static/68.png) no-repeat;
    background-size: cover;
}

/* .registrationdiv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
} */

.Registration_pp1 {
    position: relative;
    width: 70%;

    min-height: 490px;
    /* top: 25px; */
    /* right: 0; */
    background: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 50px 0;
    left: 30%;
    top: -275px;
}

.valspan {
    width: 70px;
    display: inline-block;
}

.Registrationcenterdiv {
    width: 50%;
    min-width: 500px;
    margin: 0 auto;
}

.Registration_p1 {
    font-size: 58px;
    font-weight: bold;
    margin-bottom: 10px;
    word-break: break-word;
}

.Registration_p2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 15px;
    font-family: helvetica-w01-bold, helvetica-w02-bold, helvetica-lt-w10-bold,
        sans-serif;
    word-break: break-word;
}

.Registration_p22 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 15px;
    font-family: helvetica-w01-bold, helvetica-w02-bold, helvetica-lt-w10-bold,
        sans-serif;
    word-break: break-word;
}

.Registration_p3 {
    font-size: 16px;
}

.editp1,
.editp2 {
    width: 395px;
    box-sizing: border-box;
    padding: 0 2%;
    word-break: break-word;
}

.editp1 {
    padding-top: 40px;
    text-shadow: #c8c8c8 1px 1px 0px, #b4b4b4 0px 2px 0px, #a0a0a0 0px 3px 0px,
        rgba(140, 140, 140, 0.498039) 0px 4px 0px, #787878 0px 0px 0px,
        rgba(0, 0, 0, 0.498039) 0px 5px 10px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    line-height: 50px;
    word-break: break-word;
}

.editp2 {
    text-shadow: #c8c8c8 1px 1px 0px, #b4b4b4 0px 2px 0px, #a0a0a0 0px 3px 0px,
        rgba(140, 140, 140, 0.498039) 0px 4px 0px, #787878 0px 0px 0px,
        rgba(0, 0, 0, 0.498039) 0px 5px 10px;
    color: #fff;
    font-size: 15px;
    line-height: 30px;
    word-break: break-word;
}

.detailsipt {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.detailsipt div {
    margin-right: 30px;
    font-size: 16px;
    font-weight: 100;
    word-break: break-word;
}

.detailsipt div p {
    margin-bottom: 15px;
    font-weight: lighter;
    word-break: break-word;
}

.iptdiv {
    border: none;
    border-bottom: 1px solid #000;
}

.liptdiv {
    display: block;
    border: none;
    border-bottom: 1px solid #000;
}

.short_p6div {
    text-align: center;
}

.short_p66 {
    letter-spacing: 1px;
    display: inline-block;
    width: 147px;
    height: 40px;
    line-height: 40px;
    background: #5fb6c7;
    color: #fff;
    text-align: center !important;
    cursor: pointer;
    transition: all 0.2s ease, visibility 0s;
    border: 3px solid #5fb6c7;
    font-size: 15px;
    /* margin-top: 25px; */
}

.short_p66:hover {
    background: #fff;
    border: 3px solid rgb(129, 150, 153);
    color: rgb(129, 150, 153);
}

.short_p77 {
    letter-spacing: 1px;
    display: inline-block;
    width: 147px;
    height: 40px;
    line-height: 40px;
    background: #fff;
    color: #000;
    text-align: center !important;
    cursor: pointer;
    transition: all 0.2s ease, visibility 0s;
    border: 3px solid #5fb6c7;
    font-size: 15px;
    /* margin-top: 25px; */
    margin-bottom: 0;
}

.short_p77:hover {
    background: #fff;
    border: 3px solid rgb(129, 150, 153);
    color: rgb(129, 150, 153);
}

.Artwork {
    width: 100%;
}

.smalltitle {
    font-size: 16px;
    font-weight: bold;
    word-break: break-word;
}

.sstitle {
    font-size: 12px;
    word-break: break-word;
}

.Uploaddiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    word-break: break-word;
}

.Documentspan {
    font-size: 15px;
    line-height: 26px;
    width: 300px;
    word-break: break-word;
}

.subtext {
    font-size: 12px;
    color: #c283b2;
    line-height: 20px;
    margin-bottom: 25px;
    word-break: break-word;
}

.subtext p {
    word-break: break-word;
}

.linkipt {
    height: 36px;
    line-height: 36px;
    border: 2px solid #eee;
    box-sizing: border-box;
    padding-left: 10px;
    word-break: break-word;
}

.threebut {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

/* 手机屏幕的字体大小 */
@media screen and (max-width: 900px) {
    .contanctdiv {
        width: 100%;
        margin: 0;
        left: 0;
        display: flex;
        flex-wrap: wrap;
        /* flex-direction: column-reverse; */
    }

    .Contacttext {
        width: 100%;
    }

    .Registration_p {
        position: relative;
    }

    .registrationdiv {
        width: 100%;
        height: auto;
    }

    .editp1,
    .editp2 {
        width: 100%;
    }

    .Registration_pp1 {
        min-width: 100%;
        width: 100%;
        padding: 0 10px;
        margin-bottom: 50px;
    }

    .contanctdiv {
        box-sizing: border-box;
        padding-top: 0;
        padding-bottom: 50px;
    }

    .threebut {
        justify-content: space-between;
    }

    .short_p7 {}

    .short_p6 {
        /* margin: 0; */
    }

    .detailsipt div {
        width: 100%;
        margin-bottom: 15px;
        margin-right: 0;
    }

    .detailsipt div input {
        width: 100%;
    }

    .Registration_p2 {
        margin-bottom: 5px;
    }

    .Registrationcenterdiv {
        width: 100%;
        min-width: 100%;
        box-sizing: border-box;
        padding-bottom: 30px;
    }

    .artimg {
        width: 70px;
        height: 70px;
    }

    .artimgdiv {
        display: inline-block;
        position: relative;
    }

    .artimgdiv:hover .imgcaozuo {
        display: block;
    }

    .artimgdiv i {
        margin: 0 5px;
    }

    .imgcaozuo {
        position: absolute;
        width: 70px;
        height: 70px;
        text-align: center;
        line-height: 70px;
        top: 0;
        left: 0;
        background-color: rgb(120, 120, 120, 0.6);
        display: none;
    }

    .contactcon3 {
        /* min-height: 2300px; */
    }

    .Registration_pp1 {
        top: 0;
        left: 0;
    }

    .liptdiv {
        width: 100px !important;
    }
}
</style>
