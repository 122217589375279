<template>
    <div class="alldiv">
        <head_Menu :parentmsg="5"></head_Menu>
        <div class="logindiv">
            <div class="logindiv1">
                <div class="logindiv4">
                    <div class="logintit">{{ $t('login.msg1') }}</div>
                    <div class="regist">{{ $t('login.msg2') }} <span @click="Changetab('/Registration')">{{ $t('login.msg3')
                    }}</span></div>
                    <div class="logindiv3">
                        <p>{{ $t('login.msg4') }}</p>
                        <input v-model="username" type="text">
                    </div>
                    <div class="logindiv3">
                        <p>{{ $t('login.msg5') }}</p>
                        <input v-model="password" :type="showpsd ? 'text' : 'password'">
                    </div>
                    <div class="logindiv3">
                        <el-checkbox @change="getcheck" v-model="checked">{{ $t('login.msg6') }}</el-checkbox>
                        <el-checkbox v-model="showpsd">{{ $t('forgot.msg5') }}</el-checkbox>
                    </div>
                    <div>
                        <span class="register1" @click="submit">{{ $t('login.msg1') }}</span>
                    </div>
                    <div class="regist Forget" @click="Changetab('/Forgot')">{{ $t('login.msg7') }} </div>
                </div>

            </div>
            <div class="logindiv2">

            </div>
        </div>
        <Footpage :foot="1"></Footpage>
    </div>
</template>
<script>
import {
    login
} from '@/api/index'
export default {
    name: 'Terms',
    data() {
        return {
            showpsd: false,
            msg: '头部',
            checked: false,
            password: "",
            username: "",
        };
    },
    created() {
        this.password = localStorage.getItem('password')
        this.username = localStorage.getItem('username')
        if (localStorage.getItem('password') && localStorage.getItem('username')) {
            this.checked = true
        }
    },
    methods: {
        submit() {
            const data = {
                password: this.password,
                username: this.username,
            }
            login(data).then(res => {
                if (res.data.code == 200) {
                    localStorage.setItem('token', res.data.token)
                    if (this.username == 'admin') {
                        this.$router.push({
                            path: '/artlist',
                        });
                    } else {
                        this.$router.push({
                            path: '/sumbit',
                        });
                    }

                }
            })
        },
        Changetab(item) {
            this.$router.push({
                path: item
            })
        },
        getcheck(val) {
            console.log(val)
            if (val) {
                localStorage.setItem('username', this.username)
                localStorage.setItem('password', this.password)
            } else {
                localStorage.removeItem('username')
                localStorage.removeItem('password')
            }
        }
    }
}
</script>
<style scoped>
.logindiv {
    display: flex;
    flex-wrap: wrap;
}

.logindiv1,
.logindiv2 {
    width: 50%;
}

.logindiv2 {
    background-image: radial-gradient(circle at 3.6751302083333335% 6.940104166666666%, #4D6001 0%, 11.55%, rgba(77, 96, 1, 0) 35%), radial-gradient(circle at 92.96712239583333% 9.554036458333332%, #FAAA63 0%, 17.5%, rgba(250, 170, 99, 0) 35%), radial-gradient(circle at 95.20670572916666% 90.48177083333333%, #C9C6FF 0%, 34.1%, rgba(201, 198, 255, 0) 55%), radial-gradient(circle at 4.313151041666666% 93.34635416666667%, #97B49D 0%, 29.900000000000002%, rgba(151, 180, 157, 0) 65%), radial-gradient(circle at 46.555989583333336% 11.50390625%, rgba(214, 222, 72, 0.99) 0%, 25%, rgba(214, 222, 72, 0) 50%), radial-gradient(circle at 51.121419270833336% 89.98697916666667%, #93C3FF 0%, 42%, rgba(147, 195, 255, 0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255, 255, 255, 0) 100%);
}

.register1 {
    letter-spacing: 1px;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 15px;
    /* width: 445px; */
    height: 40px;
    line-height: 40px;
    background: #000;
    color: #fff;
    text-align: center !important;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.2s ease, visibility 0s;
    border: 1px solid #000;
    margin-bottom: 30px;
    margin-top: 20px;
}

.logindiv4 {
    width: 300px;
    margin: 0 auto;
}

.logindiv3 {
    text-align: left;
}

.logintit {
    font-size: 56px;
    font-weight: bold;
}

.regist {
    text-align: left;
    margin-bottom: 30px;
}

.regist span {
    text-decoration: underline;
    cursor: pointer;
}

.logindiv3 {
    width: 100%;
}

.logindiv3 input {
    width: 100%;
    height: 40px;
    margin: 5px 0;
}

.Forget {
    cursor: pointer;
}

@media screen and (max-width: 900px) {

    .logindiv1,
    .logindiv2 {
        width: 100%;
    }

    .logindiv4 {
        width: 100%;
        box-sizing: border-box;
        padding: 0 10px;
    }

    .logindiv2 {
        height: 200px;
    }
}
</style>