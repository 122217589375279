// import request from "@/utils/request";
// // 查询分类列表
// export function login(data) {
//   return request({
//     url: "/login",
//     method: "post",
//     data: data,
//   });
// }
import jyRequest from "@/utils/request";
// 登录验证请求
export function login(account) {
  return jyRequest.post({
    url: "/login",
    data: account,
  });
}
export function register(data) {
  return jyRequest.post({
    url: "/register",
    data: data,
  });
}
export function userDetail(data) {
  return jyRequest.post({
    url: "/system/user/userDetail",
    data: data,
  });
}
export function artadd(data) {
  return jyRequest.post({
    url: "/art/add",
    data: data,
  });
}
export function updateUser(data) {
  return jyRequest.post({
    url: "/system/user/updateUser",
    data: data,
  });
}
export function updatePwd(data) {
  return jyRequest.post({
    url: "/system/user/updatePwd",
    data: data,
  });
}
export function mailsend(data) {
  return jyRequest.post({
    url: "/mail/send",
    data: data,
  });
}
export function resetPassword(data) {
  return jyRequest.post({
    url: "/system/user/resetPassword",
    data: data,
  });
}
export function artinfo(data) {
  return jyRequest.get({
    url: "/art/" + data,
  });
}
export function artlist(data) {
  return jyRequest.get({
    url: "/art/list",
    params: data,
  });
}
export function listUser(data) {
  return jyRequest.get({
    url: "/system/user/list",
    params: data,
  });
}
