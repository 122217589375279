<template>
  <div class="">
    <head_Menu :parentmsg="1"></head_Menu>
    <!-- <div>{{ $t('main.message') }}</div> -->
    <div class="content">
      <div class="top1">
        <div class="texttop1">
          <div class="div418">
            <p class="tit1 animate__animated animate__fadeInRight animate__slow">{{ $t('about.msg1') }}</p>
            <p class="tit2 animate__animated animate__fadeInRight animate__slow">{{ $t('about.msg2') }} </p>
            <div class="tit3div">
              <p class="tit3 animate__animated animate__fadeInRight animate__slow" v-html="$t('about.msg3')"> </p>
            </div>
          </div>

          <div class="mobiletit3">
            <p class="tit3 animate__animated animate__fadeInRight animate__slow">{{ $t('about.msg3') }} </p>
          </div>
        </div>
        <div class="texttop2">
          <img src="../assets/static/8.png" alt="">
        </div>
      </div>
      <div class="top4">
        <img class="MazNVa" src="../assets/static/26.png" alt="">
        <img class="mobileMazNVa" src="../assets/static/27.png" alt="">
        <p class="Missiontitlt animate__animated animate__fadeInRight animate__slow">
          {{ $t('about.msg4') }} </p>
        <p class="Missioncon animate__animated animate__fadeInRight animate__slow">{{ $t('about.msg5') }} </p>
      </div>
      <!-- <div class="top2">
        <div class="topdiv2">
          <img src="../assets/static/9.png" alt="">
          <p class="tit4  animate__animated animate__fadeInRight animate__slow">CEO’s Welcome</p>
          <p class="tit3 tit5 animate__animated animate__fadeInRight animate__slow">I'm a paragraph. Click here to add
            your own
            text and edit me. It’s easy. Just click “Edit Text” or double
            click me to add your own content and make changes to the font. Feel free to drag and drop me anywhere you like
            on your page. I’m a great place for you to tell a story and let your users know a little more about you.</p>
        </div>
        <div class="topdiv3">
          <img src="../assets/static/10.png" alt="">
          <p class="tit4 animate__animated animate__fadeInRight animate__slow">
            Mission</p>
          <p class="tit3 tit5 animate__animated animate__fadeInRight animate__slow">Our mission is to provide a
            transformative,
            globally-focused education, cultivating cross-cultural understanding. Through a diverse curriculum, we empower
            students with the skills needed to navigate an interconnected world. Ruthin Education Group strives to be a
            beacon of academic brilliance, shaping individuals who not only excel academically but also contribute
            meaningfully to a global society. In essence, our mission is to nurture well-rounded, culturally-aware leaders
            for the future.</p>
        </div>
      </div> -->
      <div class="top3">
        <div>
          <p class="top_p3 animate__animated animate__fadeInRight animate__slow">{{ $t('about.msg6') }}</p>
          <p class="top_p4 animate__animated animate__fadeInRight animate__slow"><span></span> {{ $t('about.msg7') }}</p>
          <p class="top_p4 animate__animated animate__fadeInRight animate__slow"><span></span>{{ $t('about.msg8') }}</p>
          <p class="top_p4 animate__animated animate__fadeInRight animate__slow"><span></span> {{ $t('about.msg9') }}</p>
          <p class="top_p4 animate__animated animate__fadeInRight animate__slow"><span></span> {{ $t('about.msg10') }}</p>
          <p class="top_p4 animate__animated animate__fadeInRight animate__slow"><span></span>{{ $t('about.msg11') }}</p>
        </div>
      </div>
    </div>
    <Footpage></Footpage>
  </div>
</template>
<script>
export default {
  name: 'HeadMenu',
  data() {
    return {
      msg: '头部'
    };
  },
  methods: {
    Changetab(item) {
      this.$router.push({
        path: item
      })
    }
  }
  // props: {
  //   msg: String
  // }
}
</script>
<style scoped>
.top1,
.top2 {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

}

.top2 {
  margin: 30px auto;
}

.texttop1 {
  box-sizing: border-box;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  background-color: rgb(228, 224, 217);
}

.texttop1,
.texttop2 {
  width: 50%;
  /* min-height: 630px; */
}

.texttop2 img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
}

.tit1 {
  font-size: 66px;
  font-weight: bold;
  color: #000;
  text-align: left;
}

.tit2 {
  font-size: 66px;
  font-weight: 500;
  color: #000;
  text-align: left;
  word-break: break-word;
}

.tit3 {
  font-size: 17px;
  line-height: 32px;
  text-align: left;
  word-break: break-word;
}

.tit3div {
  display: block;
}

.mobiletit3 {
  display: none;
}

.topdiv2 {
  width: 50%;
  background: rgb(129, 150, 153);
  box-sizing: border-box;
  padding: 30px 40px 30px 70px;
  text-align: left;
}

.topdiv3 {
  width: 50%;
  background: rgb(237, 210, 146);
  box-sizing: border-box;
  padding: 30px 40px 30px 70px;
  text-align: left;

}

.topdiv2 img,
.topdiv3 img {
  width: 439px;
}

.tit4 {
  font-size: 42px;
  font-weight: bold;
  color: #000;

}

.top3 {
  width: 90%;
  margin: 0 auto;
  min-height: 536px;
  background: url(../assets/static/30.png) no-repeat;
  background-size: cover;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top3 p {
  margin-left: 30%;
  text-align: left;

}

.top_p3 {
  font-size: 42px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #fff;
}

.top_p4 {
  font-size: 44px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: rgb(192, 203, 204);
  margin-bottom: 10px;

}

.top_p4 span {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: rgb(192, 203, 204);
  border-radius: 20px;
  margin-right: 15px;
  margin-left: 1em;
}

.div418 {
  max-width: 418px;
  margin: 0 auto;
}

.top4 {
  width: 880px;
  margin: 40px auto;
  text-align: left;
}

.Missiontitlt {
  font-size: 42px;
  font-weight: bold;
  margin: 10px 0;
}

.Missioncon {
  font-size: 17px;
  line-height: 32px;
  word-break: break-word;
}

.MazNVa {
  display: block;
  width: 100%;
}

.mobileMazNVa {
  display: none;
}

/* 手机屏幕的字体大小 */
@media screen and (max-width: 900px) {
  .mobileMazNVa {
    display: block;
    width: 100%;
  }

  .MazNVa {
    display: none;
  }

  .texttop1,
  .texttop2,
  .topdiv2,
  .topdiv3 {
    width: 100%;
    height: auto;
  }

  .texttop1 {
    padding: 20px;
  }

  .top1 {
    width: 100%;
    flex-direction: column-reverse;
  }

  .top2,
  .top3 {
    width: 100%;
  }

  .top3 {
    margin: 0 auto;
    min-height: 536px;
    background: url(../assets/static/28.png) no-repeat;
    background-size: contain;
    margin-bottom: 40px;
    display: flex;
    flex-direction: unset;
    justify-content: unset;
    box-sizing: border-box;
    padding-top: 50px;
  }

  .topdiv2,
  .topdiv3 {
    padding: 30px 0;
  }

  .texttop2 {
    height: 247px;
  }

  .texttop2 img {
    height: 247px;
  }

  .tit1,
  .tit2 {
    font-size: 29px;
  }

  .tit3 {
    word-break: break-word;
  }

  .tit3div {
    display: none;
  }

  .mobiletit3 {
    display: block;
  }

  .topdiv2 img,
  .topdiv3 img {
    width: 100%;
  }

  .tit4,
  .tit5 {
    box-sizing: border-box;
    padding: 20px;
  }

  .top3 p {
    margin-left: 30px;
  }

  .top_p3,
  .top_p4 {
    font-size: 24px;
  }

  .top_p4 span {
    width: 9px;
    height: 9px;
  }

  .div418 {
    width: 100%;
  }

  .top4 {
    width: 100%;
    margin: 40px auto;
    text-align: left;
  }

  .Missiontitlt,
  .Missioncon {
    box-sizing: border-box;
    padding: 0 20px;
    word-break: break-word;
  }
}
</style>