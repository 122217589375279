<template>
    <div class="about">
        <head_Menu :parentmsg="5"></head_Menu>
        <div class="contactcon">
            <div class="contanctdiv">
                <div class="registrationdiv">
                    <img src="../assets/static/61.png" alt="" />
                </div>
                <div class="Registration_p">
                    <p class="Registration_p1">{{ $t('Registration.msg1') }}</p>
                    <p class="Registration_p2">{{ $t('Registration.msg2') }}</p>
                    <p class="Registration_p3">{{ $t('Registration.msg3') }}</p>
                </div>
            </div>
        </div>
        <div class="detailsdiv">
            <div class="Schooltitle1">{{ $t('Registration.msg4') }}</div>
            <div class="Schooltitle2">{{ $t('Registration.msg5') }}</div>
            <el-form label-position="top" ref="ruleForm" label-width="80px" :inline="true" :rules="rules" :model="ruleForm">
                <el-form-item class="typeitem" label="" prop="passThirteen">
                    <el-checkbox-group v-model="ruleForm.passThirteen">
                        <el-checkbox :label="$t('Registration.msg6')" name="type"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item class="typeitem" :label="$t('Registration.msg7')" prop="schoolName">
                    <el-input v-model="ruleForm.schoolName" :placeholder="$t('Registration.msg7')"></el-input>
                    <!-- <span slot="error" class="item-error">
                        <div class="tip">
                            <div class="box1">
                                <img src="../assets/static/63.png" alt="">
                                This field is required.
                            </div>
                        </div>
                    </span> -->
                </el-form-item>
                <el-form-item class="typeitem school" :label="$t('Registration.msg8')" prop="schoolAddress1">
                    <el-input class="bottomipt upipt" v-model="ruleForm.schoolAddress1"
                        :placeholder="$t('Registration.msg9')"></el-input>
                    <!-- <span slot="error" class="item-error">
                        <div class="tip">
                            <div class="box1">
                                <img src="../assets/static/63.png" alt="">
                                This field is required.
                            </div>
                        </div>
                    </span> -->
                </el-form-item>
                <el-form-item class="typeitem school">
                    <el-input class="bottomipt" v-model="ruleForm.schoolAddress2"
                        :placeholder="$t('Registration.msg10')"></el-input>
                </el-form-item>
                <el-form-item class="widthipt" prop="city">
                    <el-input class="bottomipt" v-model="ruleForm.city" :placeholder="$t('Registration.msg11')"></el-input>
                    <!-- <span slot="error" class="item-error">
                        <div class="tip">
                            <div class="box1">
                                <img src="../assets/static/63.png" alt="">
                                This field is required.
                            </div>
                        </div>
                    </span> -->
                </el-form-item>
                <!-- 地区 -->
                <el-form-item class="widthipt" prop="area">
                    <el-input class="bottomipt" v-model="ruleForm.area" :placeholder="$t('Registration.msg12')"></el-input>
                    <!-- <span slot="error" class="item-error">
                        <div class="tip">
                            <div class="box1">
                                <img src="../assets/static/63.png" alt="">
                                This field is required.
                            </div>
                        </div>
                    </span> -->
                </el-form-item>
                <!-- 邮编 -->
                <el-form-item class="widthipt" prop="zipCode">
                    <el-input class="bottomipt" v-model="ruleForm.zipCode"
                        :placeholder="$t('Registration.msg13')"></el-input>
                </el-form-item>
                <!-- 城市 -->
                <el-form-item class="widthipt" prop="country">
                    <el-input class="bottomipt" v-model="ruleForm.country"
                        :placeholder="$t('Registration.msg29')"></el-input>
                    <!-- <span slot="error" class="item-error">
                        <div class="tip">
                            <div class="box1">
                                <img src="../assets/static/63.png" alt="">
                                This field is required.
                            </div>
                        </div>
                    </span> -->
                </el-form-item>

                <div class="Schooltitle1">{{ $t('Registration.msg14') }}</div>
                <div class="Schooltitle2">{{ $t('Registration.msg15') }}</div>
                <!-- <el-form label-position="top" label-width="80px" :inline="true" :rules="rules" :model="RegistrationForm"> -->
                <!-- 名 -->
                <el-form-item class="leftitem widthiptleft" :label="$t('Registration.msg16')" prop="name">
                    <el-input class="" v-model="ruleForm.name" :placeholder="$t('Registration.msg17')"></el-input>
                </el-form-item>
                <!-- 姓 -->
                <el-form-item class="leftitem" label=" " prop="surname">
                    <el-input class="" v-model="ruleForm.surname" :placeholder="$t('Registration.msg18')"></el-input>
                </el-form-item>
                <!-- 生日 -->
                <el-form-item class="typeitem" :label="$t('Registration.msg19')" prop="birthday">
                    <!-- <el-input class="" v-model="ruleForm.region" placeholder="City"></el-input> -->
                    <el-date-picker v-model="ruleForm.birthday" type="date" lang="en" :picker-options="expireTimeOPtion"
                        :placeholder="$t('Registration.msg19')">
                    </el-date-picker>
                </el-form-item>
                <!-- 邮箱 -->
                <el-form-item class="typeitem" :label="$t('Registration.msg20')" prop="email">
                    <el-input class="" v-model="ruleForm.email" :placeholder="$t('Registration.msg20')"></el-input>
                </el-form-item>
                <!-- 电话 -->
                <el-form-item class="typeitem " :label="$t('Registration.msg21')" prop="number">
                    <!-- <el-select class="quhao" v-if="locale == 'zn'" v-model="ruleForm.quhao" placeholder="区号">
                        <el-option v-for="item in phonelist" :key="item.value" :label="`${item.name}+${item.tel}`"
                            :value="item.tel">
                        </el-option>
                    </el-select> -->
                    <!-- v-if="locale == 'en'" -->
                    <el-select class="quhao" v-model="ruleForm.quhao1" placeholder="区号">
                        <el-option v-for="item in phonelist" :key="item.value" :label="`${item.en} +${item.tel}`"
                            :value="item.tel">
                        </el-option>
                    </el-select>
                    <el-input class="phonenumber" v-model="ruleForm.number"
                        :placeholder="$t('Registration.msg21')"></el-input>
                </el-form-item>
                <!-- 地址 -->
                <el-form-item class="typeitem school" :label="$t('Registration.msg22')" prop="streetOfResidence1">
                    <el-input class="bottomipt upipt" v-model="ruleForm.streetOfResidence1"
                        :placeholder="$t('Registration.msg9')"></el-input>
                </el-form-item>
                <el-form-item class="typeitem school">
                    <el-input class="bottomipt" v-model="ruleForm.streetOfResidence2"
                        :placeholder="$t('Registration.msg10')"></el-input>
                </el-form-item>
                <!-- //居住地区 -->
                <el-form-item class="leftitem widthiptleft" prop="cityOfResidence">
                    <el-input class="bottomipt" v-model="ruleForm.cityOfResidence"
                        :placeholder="$t('Registration.msg11')"></el-input>
                </el-form-item>
                <el-form-item class="leftitem" prop="areaOfResidence">
                    <el-input class="bottomipt" v-model="ruleForm.areaOfResidence"
                        :placeholder="$t('Registration.msg12')"></el-input>
                </el-form-item>
                <el-form-item class="leftitem widthiptleft" prop="zipCodeOfResidence">
                    <el-input class="bottomipt" v-model="ruleForm.zipCodeOfResidence"
                        :placeholder="$t('Registration.msg13')"></el-input>
                </el-form-item>
                <el-form-item class="leftitem" prop="countryOfResidence">
                    <el-input class="bottomipt" v-model="ruleForm.countryOfResidence"
                        :placeholder="$t('Registration.msg29')"></el-input>
                </el-form-item>
                <el-form-item class="typeitem" :label="$t('Registration.msg23')" prop="password">
                    <el-input class="" v-model="ruleForm.password" :placeholder="$t('Registration.msg23')"></el-input>
                </el-form-item>
                <el-form-item class="typeitem" :label="$t('Registration.msg24')" prop=cofimpassword>
                    <el-input class="" v-model="ruleForm.cofimpassword" :placeholder="$t('Registration.msg24')"></el-input>
                </el-form-item>
                <el-form-item class="typeitem" label="" prop="regist">
                    <el-checkbox-group v-model="ruleForm.regist">
                        <el-checkbox :label="$t('Registration.msg25')" name="type"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item class="typeitem" label="" prop="agree">
                    <el-checkbox-group v-model="ruleForm.agree">
                        <el-checkbox :label="$t('Registration.msg26')" name="type"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <el-button type="primary" @click="onSubmit">{{ $t('Registration.msg27') }}</el-button>
            <div class="Privacy">
                <a href="https://ruthinschoolcouk-my.sharepoint.com/:w:/g/personal/luy_ruthinschool_co_uk/EcK8ZAYOOVZIuQsGeywBsH8BSy0NusWjmNwGrNpLSqN5hg?rtime=nz6zc1cw3Eg"
                    target="_blank" rel="noopener noreferrer"><span>{{ $t('Registration.msg28') }}</span></a>
            </div>
        </div>
        <div class="Conditions">
            <div class="Conditionsdiv">
                <p class="Conditionsp1">
                    {{ $t('terms.msg1') }}</p>
                <p class="Conditionsp2">{{ $t('terms.msg2') }}
                </p>

                <div class="artwork" v-if="showterms">
                    <p>{{ $t('terms.msg3') }}</p>
                    <p>{{ $t('terms.msg4') }}</p>
                    <p>{{ $t('terms.msg5') }}</p>
                    <p>{{ $t('terms.msg6') }}</p>
                    <p>{{ $t('terms.msg7') }}</p>
                    <p>{{ $t('terms.msg8') }}</p>
                    <p>{{ $t('terms.msg9') }}</p>
                    <p>{{ $t('terms.msg10') }}</p>
                    <p>{{ $t('terms.msg11') }}</p>
                    <p>{{ $t('terms.msg12') }}</p>
                    <p>{{ $t('terms.msg13') }}</p>
                    <p>{{ $t('terms.msg14') }}</p>
                    <p>{{ $t('terms.msg15') }}</p>
                    <p>{{ $t('terms.msg16') }}</p>
                    <p>{{ $t('terms.msg17') }}</p>
                    <p>{{ $t('terms.msg18') }}</p>
                    <p>{{ $t('terms.msg19') }}</p>
                    <p>{{ $t('terms.msg20') }}</p>
                    <p>{{ $t('terms.msg21') }}</p>
                    <p>{{ $t('terms.msg22') }}</p>
                    <p>{{ $t('terms.msg23') }}</p>
                    <p>{{ $t('terms.msg24') }}</p>
                    <p>{{ $t('terms.msg25') }}</p>
                    <p>{{ $t('terms.msg26') }}</p>
                    <p>{{ $t('terms.msg27') }}</p>
                    <p>{{ $t('terms.msg28') }}</p>
                    <p>{{ $t('terms.msg29') }}</p>
                    <p>{{ $t('terms.msg30') }}</p>
                    <p>{{ $t('terms.msg31') }}</p>
                    <p>{{ $t('terms.msg32') }}</p>
                    <p>{{ $t('terms.msg33') }}</p>
                    <p>{{ $t('terms.msg34') }}</p>
                    <p>{{ $t('terms.msg35') }}</p>
                    <p>{{ $t('terms.msg36') }}<a data-auto-recognition="true" href="mailto:arts@ruthineducation.co.uk"
                            class="wixui-rich-text__text email">arts@ruthineducation.co.uk</a></p>
                    <p>{{ $t('terms.msg37') }}</p>
                </div>
                <p class="Conditionsp3" @click="handlemore">
                    <span>{{ showterms ? $t('forgot.msg8') : $t('forgot.msg7') }}</span>
                </p>
            </div>
        </div>

        <Footpage :foot="1"></Footpage>
    </div>
</template>
<script>
import listjson from '../utils/json'
import Vue from "vue";
import ElementUI from "element-ui";
import localeZhCN from "element-ui/lib/locale/lang/zh-CN"; // 导入中文语言包
import localeEnUS from "element-ui/lib/locale/lang/en"; // 导入英文语言包
import { DatePicker } from "element-ui";
import {
    register
} from '@/api/index'
console.log(listjson.phonelist)
// 注册Element UI组件
Vue.use(ElementUI);
// import countryCodeSelector from 'vue-country-code-selector'
export default {
    name: "Registration",
    // components: {
    //     countryCodeSelector
    // },
    data() {
        var validatePass = (rule, value, callback) => {
            if (value.length < 6) {
                callback(new Error('The password must contain at least 6 characters'));
            } else {
                if (this.ruleForm.confirmPwd !== '') {
                    this.$refs.ruleForm.validateField('confirmPwd');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('This field is required.'));
            } else if (value !== this.ruleForm.password) {
                callback(new Error('The two passwords are inconsistent!'));
            } else {
                callback();
            }
        };
        // 邮箱
        var email = (rule, value, callback) => {
            const mal = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
            // const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
            if (rule.required && !value) {
                return callback(new Error('This field is required.'))
            }
            if (value) {
                if (!(mal.test(value))) {
                    callback(new Error('Please enter the correct email address'))
                } else {
                    callback()
                }
            }
        }
        const validatePassword = (rule, value, callback) => {
            if (value.length < 6) {
                callback(new Error("密码必须是8位以上、必须含有字母、数字、特殊符号"));
            } else {
                callback();
            }
        };

        return {
            showterms: false,
            locale: this.$i18n.locale,
            expireTimeOPtion: {
                disabledDate(time) {
                    const start = new Date('2005/01/01'); // 设置起始时间
                    const end = new Date(); // 设置结束时间
                    // return time.getTime() > Date.now() - 8.64e6;  //如果没有后面的-8.64e6就是不可以选择今天的 
                    if (time.getTime() > end || time.getTime() < start) {
                        return true; // 超过或小于指定范围则返回true，表示不可选
                    } else {
                        return false; // 否则返回false，表示可选
                    }
                }

            },
            rules: {
                schoolName: [{ required: true, trigger: "blur", message: 'This field is required.' }],
                schoolAddress1: [{ required: true, trigger: "blur", message: 'This field is required.' }],
                city: [{ required: true, trigger: "blur", message: 'This field is required.' }],
                area: [{ required: true, trigger: "blur", message: 'This field is required.' }],
                country: [{ required: true, trigger: "blur", message: 'This field is required.' }],
                name: [{ required: true, trigger: "blur", message: 'This field is required.' }],
                surname: [{ required: true, trigger: "blur", message: 'This field is required.' }],
                birthday: [{ required: true, trigger: "blur", message: 'This field is required.' }],
                zipCode: [{ required: true, trigger: "blur", message: 'This field is required.' }],
                email: [{ required: true, trigger: "blur", validator: email }],
                number: [{ required: true, trigger: "blur", message: 'This field is required.' }],
                streetOfResidence1: [{ required: true, trigger: "blur", message: 'This field is required.' }],
                areaOfResidence: [{ required: true, trigger: "blur", message: 'This field is required.' }],
                cityOfResidence: [{ required: true, trigger: "blur", message: 'This field is required.' }],
                countryOfResidence: [{ required: true, trigger: "blur", message: 'This field is required.' }],
                password: [{ required: true, validator: validatePass, trigger: "blur" }],
                cofimpassword: [{ validator: validatePass2, trigger: 'blur', required: true }],
                zipCodeOfResidence: [{ required: true, trigger: "blur", message: 'This field is required.' }],
                // regist: [
                //     { type: 'array', required: true, message: 'This field is required.', trigger: 'change' }
                // ],
                agree: [
                    { type: 'array', required: true, message: 'This field is required.', trigger: 'change' }
                ],
                passThirteen: [
                    { type: 'array', required: true, message: 'This field is required.', trigger: 'change' }
                ],
            },
            RegistrationForm: {},
            phonelist: listjson.phonelist,
            ruleForm: {
                regist: [],
                agree: [],
                passThirteen: [],//13岁
                schoolName: "",//学校名称
                schoolAddress1: '',//学校地址1
                schoolAddress2: '',//学校地址1
                city: '',//城市
                zipCodeOfResidence: '',
                area: '',//区
                country: '',//国家
                name: '',//名
                surname: '',//姓
                birthday: '',//生日
                zipCode: '',
                email: '',//邮箱
                quhao: '86',
                quhao1: '86',
                number: '',//手机号
                streetOfResidence1: '',//居住街道
                areaOfResidence: '',//居住地区
                cityOfResidence: '',//居住地区
                countryOfResidence: '',//居住地区
                password: '',
                cofimpassword: '',
                username: ''
            },
            options: [
                {
                    value: "选项1",
                    label: "黄金糕",
                },
                {
                    value: "选项2",
                    label: "双皮奶",
                },
                {
                    value: "选项3",
                    label: "蚵仔煎",
                },
                {
                    value: "选项4",
                    label: "龙须面",
                },
                {
                    value: "选项5",
                    label: "北京烤鸭",
                },
            ],
            value: "",
        };
    },
    methods: {
        handlemore() {
            this.showterms = !this.showterms
        },
        onSubmit() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    const data = this.ruleForm
                    data.phonenumber = this.ruleForm.number
                    // data.phonenumber = '+' + this.ruleForm.quhao + ' ' + this.ruleForm.number
                    data.username = this.ruleForm.email//邮箱

                    console.log(data)
                    register(data).then(response => {
                        if (response.data.code == 200) {
                            console.log(response)
                            localStorage.setItem('token', response.data.token)
                            this.$router.push({
                                path: '/sumbit',
                            });
                        }

                    })
                    // alert('submit!');
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
    },
};
// body {
//     background: rgb(193, 220, 220);
// }
</script>
<style scoped>
.about {
    background: rgb(193, 220, 220);
}

.contactcon {
    width: 90%;
    margin: 0 auto;
    text-align: left;
    margin-top: 20px;
}

.contanctdiv {
    position: relative;
}

.registrationdiv {
    width: 65%;
    height: 272px;
}

.registrationdiv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Registration_p {
    position: absolute;
    width: 50%;
    min-width: 672px;
    min-height: 220px;
    top: 25px;
    right: 0;
    background: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 10%;
}

.Registration_p1 {
    font-size: 58px;
    font-weight: bold;
    margin-bottom: 10px;
}

.Registration_p2 {
    font-size: 37px;
    font-weight: bold;
}

.Registration_p3 {
    font-size: 16px;
}

.detailsdiv {
    box-sizing: border-box;
    padding: 8px;
    width: 450px;
    /* margin: 0 auto; */
    text-align: left;
    background: #fff;
    margin: 20px auto;
}

.Schooltitle1 {
    font-size: 28px;
    font-weight: bold;
    color: rgba(95, 182, 199, 1);
    margin-bottom: 10px;
    margin-top: 15px;
}

.Schooltitle2 {
    color: rgba(129, 150, 153, 1);
    font-size: 17px;
}

.el-form-item {
    margin-bottom: 0;
}

.el-form--label-top .el-form-item__label {
    padding: 0;
}

.el-form--inline .el-form-item {
    margin-right: 0;
}

.bottomipt {
    margin-top: 19px;
}

.upipt {
    margin-top: 0;
}

.widthipt {
    width: 48%;
}

.leftitem {
    width: 48%;
    margin-bottom: 10px;
}

.widthipt:nth-child(2n + 1) {
    margin-right: 4%;
}

.widthiptleft {
    margin-right: 4% !important;
}

/deep/.el-input__inner {
    border-color: rgba(214, 170, 201, 1);
}

.typeitem {
    width: 100%;
    margin-bottom: 10px;
}

.box1 img {
    width: 20px;
    margin-right: 5px;
}

.box1 {
    display: flex;
    align-items: center;
    width: 200px;
    border: 1px solid #4b4b4b;
    margin: 10px 0;
    background-color: #fff;
    position: relative;
    border-radius: 5px;
    padding: 0 5px;
}

.box1::before {
    width: 0;

    height: 0;

    content: "";

    border-width: 10px;

    border-color: transparent transparent #fff transparent;

    border-style: dashed dashed solid dashed;

    position: absolute;

    top: -20px;

    left: 10px;

    z-index: 100;
}

.box1::after {
    width: 0;

    height: 0;

    content: "";

    border-width: 11px;

    border-color: transparent transparent black transparent;

    border-style: dashed dashed solid dashed;

    position: absolute;

    top: -22px;

    left: 9px;

    z-index: 99;
}

/deep/.item-error {
    position: absolute;
    left: 0;
    top: 40px;
    z-index: 9;
}

/deep/.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before,
/deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
    display: none;
}

/deep/.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before,
/deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label::after {
    content: '*';
    color: #F56C6C;
    margin-left: 4px;
}

/deep/.el-form-item__label {
    min-height: 40px;
}

/deep/.el-checkbox__label {
    vertical-align: text-top;
    white-space: normal;
    word-break: break-all;
    width: 390px;
    color: rgba(129, 150, 153, 1);
    font-size: 17px;
    line-height: 30px;
}

/deep/.el-checkbox__input {
    margin-bottom: -10px;
}

/deep/.el-button--primary {
    margin-top: 40px;
    width: 100%;
    background: rgba(95, 182, 199, 1);
    border: none;
    color: rgba(255, 255, 255, 1);
    font: normal normal normal 21px/1.4em helvetica-w01-roman, helvetica-w02-roman, helvetica-lt-w10-roman, sans-serif;
}

/deep/.el-button--primary:hover {
    background: rgba(125, 34, 26, 1);
}

.Privacy {
    width: 100%;
    text-align: center;
    margin: 30px 0;
}

.Privacy span {
    color: #282626;
    font-size: 17px;
    letter-spacing: 0em;
    text-decoration-line: underline;
    cursor: pointer;
}

.Conditions {
    background: #fff;
    box-sizing: border-box;
    padding: 40px 0;
    text-align: left;
}

.Conditionsdiv {
    width: 915px;
    margin: 0 auto;
}

.Conditionsp1 {
    font-size: 28px;
    font-weight: bold;
}

.Conditionsp2 {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    line-height: 25px;
    color: #000000;
    font-family: futura-lt-w01-book, futura-lt-w05-book, sans-serif;
    word-break: break-word;
    margin-bottom: 15px;
}

.Conditionsp3 {
    color: #000000;
    font-family: futura-lt-w01-book, futura-lt-w05-book, sans-serif;
    font-weight: 400;
    text-decoration-line: underline;
    font-style: normal;
    cursor: pointer;
    font-size: 15px;
    margin-bottom: 15px;
    word-break: break-word;
}

.artwork p {
    margin-bottom: 20px;
    word-break: break-word;
}

.school {
    margin-bottom: 0;
}

.quhaodiv {
    display: flex;
}

.phonenumber {
    width: 70%;
}

.el-select {
    width: 30%;
}

/* 手机屏幕的字体大小 */
@media screen and (max-width: 900px) {
    .contactcon {
        width: 100%;
        margin-top: 0;
    }

    .registrationdiv {
        width: 100%;
        height: 148px;
    }

    .registrationdiv img {
        height: 148px;
        width: 100%;
        object-fit: cover;
    }

    .Registration_p {
        position: static;
        width: 100%;
        min-width: 100%;
        min-height: 170px;
        box-sizing: border-box;
        padding: 0 20px;
    }

    .Registration_p1 {
        font-size: 40px;
    }

    .Registration_p2 {
        font-size: 27px;
    }

    .detailsdiv {
        width: 100%;
    }

    /deep/.el-checkbox__label {
        width: 300px;
    }

    .Conditionsdiv {
        width: 100%;
        box-sizing: border-box;
        padding: 0 15px;
    }
}
</style>
