<template>
    <div class="">
        <head_Menu :parentmsg="5"></head_Menu>
        <div class="Guidancecon">
            <div class="Guidance">
                <p class="Guidancep1">{{ $t('Submissionguidance.msg1') }} {{ $t('Submissionguidance.msg2') }}</p>
                <p class="Guidancep2">{{ $t('Submissionguidance.msg3') }}</p>
                <ul class="Guidancep3">
                    <li>{{ $t('Submissionguidance.msg4') }}</li>
                    <li>{{ $t('Submissionguidance.msg5') }}</li>
                    <li>{{ $t('Submissionguidance.msg6') }}</li>
                    <li>{{ $t('Submissionguidance.msg7') }}</li>
                    <li>{{ $t('Submissionguidance.msg8') }}</li>
                    <li>{{ $t('Submissionguidance.msg9') }}</li>
                    <li>{{ $t('Submissionguidance.msg10') }}</li>
                    <li>{{ $t('Submissionguidance.msg11') }}</li>
                    <li>{{ $t('Submissionguidance.msg12') }}</li>
                </ul>
                <p class="Guidancep4">{{ $t('Submissionguidance.msg13') }}</p>
            </div>
            <div class="imgdivcon">
                <img src="../assets/static/62.png" alt="">
            </div>

        </div>
        <div class="colorline"></div>
        <div class="Guidancecon Guidancecon1">
            <div class="imgdivcon">
                <img src="../assets/static/62.png" alt="">
            </div>
            <div class="Guidance">
                <p class="Guidancep1">{{ $t('Submissionguidance.msg14') }}</p>
                <p class="Guidancep4">{{ $t('Submissionguidance.msg15') }}</p>
                <ul class="Guidancep3">
                    <li>{{ $t('Submissionguidance.msg16') }}</li>
                    <li>{{ $t('Submissionguidance.msg17') }}</li>
                    <li>{{ $t('Submissionguidance.msg18') }}</li>
                    <li>{{ $t('Submissionguidance.msg19') }}</li>
                    <li>{{ $t('Submissionguidance.msg20') }}</li>
                    <li>{{ $t('Submissionguidance.msg21') }}</li>
                    <li>{{ $t('Submissionguidance.msg22') }}</li>
                    <li>{{ $t('Submissionguidance.msg23') }}</li>
                    <li>{{ $t('Submissionguidance.msg24') }}</li>
                    <li>{{ $t('Submissionguidance.msg25') }}</li>
                    <li>{{ $t('Submissionguidance.msg26') }}</li>
                    <li>{{ $t('Submissionguidance.msg27') }}{{ $t('Submissionguidance.msg28') }}<a
                            data-auto-recognition="true" href="mailto:arts@ruthineducation.co.uk"
                            class="wixui-rich-text__text email">arts@ruthineducation.co.uk</a></li>

                </ul>
                <div class="short_p6div">
                    <span class="short_p6" @click="goback">{{ $t('ChangePassword.msg12') }}</span>
                </div>
            </div>


        </div>
        <Footpage :foot="1"></Footpage>
    </div>
</template>
<script>
export default {
    name: 'Contact',
    data() {
        return {
            msg: '头部'
        };
    },
    methods: {
        Changetab(item) {
            this.$router.push({
                path: item
            })
        },
        goback() {
            this.$router.go(-1)
        }
        // this.$router.go(-1)
    }
}
</script>
<style scoped>
.Guidancecon {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

}

.Guidance,
.imgdivcon {
    width: 50%;
}

.imgdivcon {
    background: radial-gradient(circle at 3.6751302083333335% 6.940104166666666%, #47BDB4 0%, 11.55%, rgba(71, 189, 180, 0) 35%), radial-gradient(circle at 92.96712239583333% 9.554036458333332%, #B6F3E8 0%, 17.5%, rgba(182, 243, 232, 0) 35%), radial-gradient(circle at 95.20670572916666% 90.48177083333333%, #32B5BE 0%, 34.1%, rgba(50, 181, 190, 0) 55%), radial-gradient(circle at 4.313151041666666% 93.34635416666667%, #97B49D 0%, 29.900000000000002%, rgba(151, 180, 157, 0) 65%), radial-gradient(circle at 46.555989583333336% 11.50390625%, rgba(255, 210, 196, 0.99) 0%, 25%, rgba(255, 210, 196, 0) 50%), radial-gradient(circle at 51.121419270833336% 89.98697916666667%, #C283B2 0%, 42%, rgba(194, 131, 178, 0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255, 255, 255, 0) 100%);
    box-sizing: border-box;
    padding: 40px 0;
}

.imgdivcon img {
    min-width: 490px;
}

.Guidance {
    box-sizing: border-box;
    padding: 0 7%;
}

.Guidancep1 {
    font-size: 40px;
    font-weight: bold;
    word-break: break-word;
    text-align: left;
}

.Guidancep2 {
    font-size: 17px;
    font-weight: bold;
    word-break: break-word;
    text-align: left;
    box-sizing: border-box;
    padding: 0 15px;
}

.Guidancep3 {
    box-sizing: border-box;
    padding: 0 35px;
}

.Guidancep3 li {
    font-weight: 100;
    list-style: disc;
    font-size: 17px;
    word-break: break-word;
    text-align: left;
    line-height: 26px;
}

.Guidancep4 {
    font-weight: 100;
    list-style: disc;
    font-size: 17px;
    word-break: break-word;
    text-align: left;
    line-height: 26px;
}

.colorline {
    width: 90%;
    margin: 30px auto;
    height: 6px;
    background: #000;
}

.short_p6 {
    letter-spacing: 1px;
    display: inline-block;
    width: 147px;
    height: 40px;
    line-height: 40px;
    background: #282626;
    color: #fff;
    text-align: center !important;
    cursor: pointer;
    transition: all 0.2s ease, visibility 0s;
    border: 1px solid #2d2d2d;
    font-size: 15px;
    margin-top: 25px;
}

.short_p6:hover {
    background: #fff;
    border: 1px solid rgb(129, 150, 153);
    color: rgb(129, 150, 153);
}

.short_p6div {
    text-align: left;
}

/* 手机屏幕的字体大小 */
@media screen and (max-width: 900px) {
    .contanctdiv {
        width: 100%;
        margin: 0;
        left: 0;
    }

    .Contacttext {
        width: 100%;
    }

    .Guidancecon {
        width: 100%;
    }

    .Guidance,
    .imgdivcon {
        width: 100%;
    }

    .imgdivcon img {
        width: 90%;
        margin: 0 auto;
        min-width: unset;
    }

    .Guidancecon1 {
        flex-direction: column-reverse;
    }

    .short_p6div {
        text-align: center;
        margin-bottom: 20px;
    }

    .Guidancep2 {
        padding: 0;
    }

    .Guidance {
        padding: 0 5%;
    }

    .Guidancep3 {
        padding-right: 0;
    }
}
</style>