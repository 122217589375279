<template>
    <div class="about">
        <head_Menu :parentmsg="5"></head_Menu>
        <div class="contactcon">
            <div class="contanctdiv">
                <div class="registrationdiv">
                    <p class="editp1">{{ $t('ChangePassword.msg1') }}</p>
                    <!-- <p class="editp2">{{ $t('ChangePassword.msg2').replace('xxxxxx', form.userName) }} </p> -->
                </div>
                <div class="Registration_p">
                    <p class="Registration_p2">

                        {{ $t('ChangePassword.msg4') }}</p>
                    <div class="detailsipt">
                        <div>
                            <p>{{ $t('ChangePassword.msg5') }}</p>
                            <input v-model="form.userName" type="text">
                        </div>
                    </div>
                    <p class="Registration_p22">
                        {{ $t('ChangePassword.msg6') }}</p>
                    <!-- <div class="detailsipt">
                        <div>
                            <p>{{ $t('ChangePassword.msg7') }}</p>
                            <input v-model="form.oldPassword" type="text">
                        </div>
                    </div> -->
                    <div class="detailsipt">
                        <div>
                            <p>{{ $t('ChangePassword.msg8') }}</p>
                            <input v-model="form.password" :type="showpsd ? 'text' : 'password'">
                        </div>
                        <div>
                            <p>{{ $t('ChangePassword.msg10') }}</p>
                            <input v-model="form.conPassword" :type="showpsd ? 'text' : 'password'">
                        </div>

                    </div>
                    <div class="">
                        <el-checkbox @change="handleshowpsd" v-model="showpsd">{{ $t('forgot.msg5') }}</el-checkbox>
                    </div>
                    <div class="short_p6div">
                        <span class="short_p6" @click="submit">{{ $t('ChangePassword.msg11') }}</span>
                    </div>
                    <div class="short_p6div">
                        <span class="short_p7" @click="goback">{{ $t('ChangePassword.msg12') }}</span>
                    </div>
                </div>


            </div>
        </div>
        <Footpage :foot="1"></Footpage>
    </div>
</template>
<script>
import {
    updatePwd, userDetail, resetPassword
} from '@/api/index'
import { Message } from "element-ui";
export default {
    name: 'UploadDetails',
    data() {
        return {
            showpsd: false,
            msg: '头部',
            form: {
                userName: '',
                email: '',
                conPassword: '',
                password: '',
                oldPassword: ''
            }
        };
    },
    created() {
        // this.getuserDetail()
    },
    methods: {
        logout() {
            localStorage.removeItem('username')
            localStorage.removeItem('password')
            localStorage.removeItem('token')
            this.$router.push({
                path: '/login'
            })
        },
        Changetab(item) {
            this.$router.push({
                path: item
            })
        },
        goback() {
            this.$router.go(-1)
        },
        getuserDetail() {
            userDetail().then(response => {
                this.form.email = response.data.data.email
                this.form.userName = response.data.data.userName
            })
        },
        submit() {
            let that = this;
            if (that.form.password != that.form.conPassword) {
                // The two passwords are inconsistent!
                Message({
                    message: this.$t('ChangePassword.msg13'),
                    type: "error",
                    center: true,
                });

            } else {
                resetPassword(that.form).then(res => {
                    this.$router.push({
                        path: '/login'
                    })
                })
            }

        }
    }
}
</script>
<style scoped>
.about {
    background: rgb(95, 182, 199);
}

.contactcon {
    width: 90%;
    min-height: 570px;
    margin: 0 auto;
    text-align: left;
    margin-top: 20px;
}

.contanctdiv {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 80px;
}

.registrationdiv {
    width: 65%;
    height: 300px;
    background: url(../assets/static/68.png) no-repeat;
    background-size: cover;
}

/* .registrationdiv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
} */

.Registration_p {
    position: absolute;
    width: 70%;
    min-width: 672px;
    min-height: 490px;
    top: 25px;
    right: 0;
    background: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 10%;
}

.Registration_p1 {
    font-size: 58px;
    font-weight: bold;
    margin-bottom: 10px;
}

.Registration_p2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 15px;
    font-family: helvetica-w01-bold, helvetica-w02-bold, helvetica-lt-w10-bold, sans-serif;
}

.Registration_p22 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 15px;
    font-family: helvetica-w01-bold, helvetica-w02-bold, helvetica-lt-w10-bold, sans-serif;
}

.Registration_p3 {
    font-size: 16px;
}

.editp1,
.editp2 {
    width: 395px;
    box-sizing: border-box;
    padding: 0 2%;
    word-break: break-word;
}

.editp2 span {
    cursor: pointer;
}

.editp1 {
    padding-top: 40px;
    text-shadow: #c8c8c8 1px 1px 0px, #b4b4b4 0px 2px 0px, #a0a0a0 0px 3px 0px, rgba(140, 140, 140, 0.498039) 0px 4px 0px, #787878 0px 0px 0px, rgba(0, 0, 0, 0.498039) 0px 5px 10px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    line-height: 50px;
}

.editp2 {
    text-shadow: #c8c8c8 1px 1px 0px, #b4b4b4 0px 2px 0px, #a0a0a0 0px 3px 0px, rgba(140, 140, 140, 0.498039) 0px 4px 0px, #787878 0px 0px 0px, rgba(0, 0, 0, 0.498039) 0px 5px 10px;
    color: #fff;
    font-size: 15px;
    line-height: 30px;
}

.detailsipt {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.detailsipt div {
    margin-right: 30px;
    font-size: 16px;
    font-weight: 100;
}

.detailsipt div p {
    margin-bottom: 15px;
    font-weight: lighter;
}

.detailsipt div input {
    border: none;
    border-bottom: 1px solid #000;
}

.short_p6div {
    text-align: center;
}

.short_p6 {
    letter-spacing: 1px;
    display: inline-block;
    width: 147px;
    height: 40px;
    line-height: 40px;
    background: #5FB6C7;
    color: #fff;
    text-align: center !important;
    cursor: pointer;
    transition: all 0.2s ease, visibility 0s;
    border: 3px solid #5FB6C7;
    font-size: 15px;
    margin-top: 25px;
}

.short_p6:hover {
    background: #fff;
    border: 3px solid rgb(129, 150, 153);
    color: rgb(129, 150, 153);
}

.short_p7 {
    letter-spacing: 1px;
    display: inline-block;
    width: 147px;
    height: 40px;
    line-height: 40px;
    background: #fff;
    color: #000;
    text-align: center !important;
    cursor: pointer;
    transition: all 0.2s ease, visibility 0s;
    border: 3px solid #5FB6C7;
    font-size: 15px;
    margin-top: 25px;
}

.short_p7:hover {
    background: #fff;
    border: 3px solid rgb(129, 150, 153);
    color: rgb(129, 150, 153);
}

/* 手机屏幕的字体大小 */
@media screen and (max-width: 900px) {
    .contanctdiv {
        width: 100%;
        margin: 0;
        left: 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .Contacttext {
        width: 100%;
    }

    .Registration_p {
        position: relative;
    }

    .registrationdiv {
        width: 100%;
        height: auto;
    }

    .editp1,
    .editp2 {
        width: 100%;
    }

    .Registration_p {
        min-width: 100%;
        width: 100%;
        padding: 0 10px;
        margin-bottom: 50px;
    }

    .contanctdiv {
        box-sizing: border-box;
        padding-top: 0;
        padding-bottom: 50px;
    }

    .short_p7 {
        margin-top: 10px;
    }

    .short_p6 {
        margin: 0;
    }

    .detailsipt div {
        margin-bottom: 15px;
    }

    .Registration_p2 {
        margin-bottom: 5px;
    }
}
</style>