<template>
    <div class="">
        <head_Menu :parentmsg="5"></head_Menu>
        <div class="line"></div>
        <div class="cutdowndiv " v-show="false">
            <count-down v-on:start_callback="countDownS_cb(1)" v-on:end_callback="countDownE_cb(1)"
                :currentTime=Number(startTime) :startTime=Number(startTime) :endTime=Number(endTime) :endText="'结束自定义文字2'"
                :dayTxt="'Days'" :hourTxt="'Hours'" :minutesTxt="'Minutes'" :secondsTxt="'Seconds'"></count-down>
        </div>
        <div class="Account">
            <div class="Account1">
                <div class="doudiv">
                    <img class="dou" src="../assets/svg/dou.svg" alt="">
                </div>
                <p class="Accountp1">{{ $t('submit.msg1') }}</p>
                <p class="Accountp2">{{ $t('submit.msg2') }}</p>
                <ul class="Accountp3">
                    <li>{{ $t('submit.msg3') }}</li>
                    <li>{{ $t('submit.msg4') }}</li>
                    <li>{{ $t('submit.msg5') }}</li>
                    <li>{{ $t('submit.msg6') }}</li>
                    <li>{{ $t('submit.msg7') }} <a data-auto-recognition="true" href="mailto:arts@ruthineducation.co.uk"
                            class="wixui-rich-text__text email">arts@ruthineducation.co.uk</a></li>
                </ul>
                <span class="short_p6" @click="Changetab('/SubmitWork')">{{ $t('submit.msg8') }} </span>
            </div>
            <div class="Account2">
                <img src="../assets/static/64.png" alt="">
            </div>
        </div>
        <div class="linecolor"></div>
        <div class="submissions">
            <div class="doudiv">
                <img class="dou" src="../assets/svg/dou.svg" alt="">
            </div>
            <div class="submissions1">
                <span class="submissionspan1">{{ $t('submit.msg9') }}</span>
                <span v-if="!artimg" class="submissionspan2">{{ $t('submit.msg10') }}</span>
                <!-- 412之前 -->
                <span v-if="info.artId && !draftFlag && ifshortlisted == 0" class="submissionspan2">{{ $t('forgot.msg19')
                }}</span>
                <!-- 412之后审核中 -->
                <span v-if="info.artId && draftFlag && ifshortlisted == 0" class="submissionspan2">{{ $t('forgot.msg20')
                }}</span>
                <!-- 未入围 -->
                <span v-if="info.artId && draftFlag && ifshortlisted == 2" class="submissionspan2">{{ $t('forgot.msg21')
                }}</span>
                <!-- 亦入围 -->
                <span v-if="info.artId && draftFlag && ifshortlisted == 1" class="submissionspan2">{{ $t('forgot.msg22')
                }}</span>
            </div>
            <div class="submissions2">
                <div class="submissions3" @click="Changetab('/SubmitWork')">
                    <img class="upload" src="../assets/svg/upload.svg" alt="">
                    <p class="uploadp">{{ $t('submit.msg11') }}</p>
                </div>
                <div class="examplediv">
                    <img v-if="artimg" class="example" :src="artimg" alt="">
                    <img v-else class="example" src="../assets/static/65.png" alt="">
                </div>
            </div>
        </div>
        <div class="linecolor1"></div>
        <div class="MoreDetails">
            <div class="Details">
                <div class="doudiv">
                    <img class="dou" src="../assets/svg/dou.svg" alt="">
                </div>
                <p class="MoreDetailstitle">{{ $t('submit.msg12') }}</p>
                <ul class="MoreDetailsul">
                    <li>{{ $t('submit.msg13') }}：<span>{{ info.name }}{{ info.surname }}</span></li>
                    <li>{{ $t('submit.msg17') }}：<span>{{ info.email }}</span></li>
                    <li>{{ $t('submit.msg14') }}：<br><span>{{ info.schoolName }}</span><br><span>{{ info.schoolAddress1 }}{{
                        info.schoolAddress2 }}</span></li>
                </ul>
                <span class="short_p61" @click="Changetab('/UploadDetails')">{{ $t('submit.msg15') }}</span>
                <span class="short_p61" @click="Changetab('/ChangePassword')">{{ $t('submit.msg16') }}</span>
            </div>
            <div class="information">
                <div class="doudiv">
                    <img class="dou" src="../assets/svg/dou.svg" alt="">
                </div>
                <p class="MoreDetailstitle">{{ $t('submit.msg18') }}</p>
                <span class="short_p61" @click="Changetab('/Submissionguidance')">{{ $t('submit.msg19') }}</span>
                <span class="short_p61" @click="openPoster">{{ $t('submit.msg20') }} </span>
                <span class="short_p61" @click="Changetab('/Terms')">{{ $t('submit.msg21') }} </span>
            </div>
        </div>
        <div class="linecolor1 linecolor2"></div>
        <Footpage :foot="1"></Footpage>
        <el-dialog class="dwidh" title="" :visible.sync="dialogVisible" :before-close="handleClose">
            <img v-if="lang == 'en'" class="postimg" src="../assets/static/69.jpg" alt="">
            <img v-else class="postimg" src="../assets/static/70.jpg" alt="">
        </el-dialog>
    </div>
</template>
<script>
import {
    login, userDetail, artinfo
} from '@/api/index'
import CountDown from '../components/countdown'
export default {
    name: 'sumbit',
    components: {
        CountDown
    },
    data() {
        return {
            startTime: '',
            endTime: '',
            info: {},
            dialogVisible: false,
            artimg: '',
            lang: this.$i18n.locale,
            draftFlag: false,
            ifshortlisted: 0,
        };
    },
    created() {
        this.getuserDetail()
    },
    methods: {
        gettime() {
            // 创建一个新的Date对象
            var currentTime = new Date();
            // 将Date对象转换为时间戳（单位为毫秒）
            this.startTime = currentTime.getTime()
            var date = new Date('2024-03-10 12:00:00'); // 将 '2021-09-30' 替换成需要获取时间戳的日期字符串
            // 调用 getTime() 方法获取该日期的时间戳（单位为毫秒）
            this.endTime = date.getTime();//跟英国相差8小时
        },
        countDownS_cb: function (x) {
            console.log(x)
        },
        countDownE_cb: function (x) {
            console.log(x)
            this.draftFlag = true
        },
        handleClose() {
            this.dialogVisible = false
        },
        openPoster() {
            this.lang = this.$i18n.locale
            console.log(this.$i18n.locale)
            this.dialogVisible = true
        },
        Changetab(item) {
            this.$router.push({
                path: item
            })
        },
        getuserDetail() {
            userDetail().then(response => {
                this.info = response.data.data
                if (response.data.data.artId) {
                    this.getartinfo(response.data.data.artId)

                }
            })
        },
        // 获取详情
        getartinfo(id) {
            let that = this;
            artinfo(id).then((res) => {
                this.artimg = res.data.data.annex,
                    this.ifshortlisted = res.data.data.draftFlag
            });
        },
    }
}
</script>
<style scoped>
.line {
    width: 100%;
    border-bottom: 1px solid #000;
    margin-top: 15px;
    margin-bottom: 5px;
}

.Account {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

}

.Account1 {
    width: 60%;
    text-align: left;
    box-sizing: border-box;
    padding-right: 10%;
}

.doudiv {
    width: 60px;
    height: 60px;
    overflow: hidden;
}

.dou {
    width: 60px;
    height: 60px;
    position: relative;
    left: -60px;
    border-right: 100px solid transparent;
    filter: drop-shadow(60px 0px 0 #3b9fc5);
}

.Accountp1 {
    font-size: 56px;
    color: #3b9fc5;
    font-weight: bold;
    margin-bottom: 10px;
    word-break: break-word;
}

.Accountp2 {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 15px;
    word-break: break-word;
}

.Accountp3 {
    box-sizing: border-box;
    padding-left: 20px;
    word-break: break-word;
}

.Accountp3 li {
    list-style-type: disc;
    font-size: 16px;
    line-height: 32px;
    word-break: break-word;
}

.Accountp3 li a {
    word-break: break-word;
}

.Account2 {
    width: 40%;
    min-width: 343px;
    min-height: 600px;
    background: linear-gradient(45deg, #5FB6C7 0%, #DBB5CC 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}

.Account2 img {
    width: 343px;
    height: 317px;
    object-fit: cover;
}

.short_p6 {
    letter-spacing: 1px;
    display: inline-block;
    width: 147px;
    height: 40px;
    line-height: 40px;
    background: #282626;
    color: #fff;
    text-align: center !important;
    cursor: pointer;
    transition: all 0.2s ease, visibility 0s;
    border: 1px solid #2d2d2d;
    font-size: 15px;
    margin-top: 25px;
}

.short_p6:hover {
    background: #fff;
    border: 1px solid rgb(129, 150, 153);
    color: rgb(129, 150, 153);
}

.short_p61 {
    display: block;
    letter-spacing: 1px;
    /* display: inline-block; */
    width: 324px;
    height: 40px;
    line-height: 40px;
    background: #5FB6C7;
    color: #fff;
    text-align: center !important;
    cursor: pointer;
    transition: all 0.2s ease, visibility 0s;
    border: 1px solid #5FB6C7;
    font-size: 15px;
    margin-top: 25px;
}

.short_p61:hover {
    background: #fff;
    border: 1px solid rgb(129, 150, 153);
    color: rgb(129, 150, 153);
}

.linecolor {
    width: 100%;
    height: 18px;
    background: #5fb6c7;
    position: relative;
    top: -18px;
}

.linecolor1 {
    width: 100%;
    height: 18px;
    background: #5fb6c7;
}

.submissions {
    width: 75%;
    margin: 0 auto;
}

.submissions1 {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    text-align: left;
    margin-top: 20px;
    word-break: break-word;
}

.submissionspan1 {
    font-size: 45px;
    font-weight: bold;
    margin-right: 30px;
    word-break: break-word;
}

.submissionspan2 {
    font-size: 19px;
    font-weight: 100;
    word-break: break-word;
}

.submissions2 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
    word-break: break-word;
}

.upload {
    width: 120px;
}

.postimg {
    width: 100%;
}

.uploadp {
    font-size: 19px;
    text-decoration: underline;
    margin-top: 10px;
    word-break: break-word;
}

.submissions3 {
    box-sizing: border-box;
    padding: 0 80px;
    word-break: break-word;
}

.MoreDetails {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #000;
    word-break: break-word;

}

.Details {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    border-right: 1px solid #000;
    padding: 30px 0;
    word-break: break-word;
}

.information {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    word-break: break-word;
}

.MoreDetailstitle {
    font-size: 26px;
    font-weight: bold;
    margin: 15px 0;
    word-break: break-word;
}

.MoreDetailsul li {
    list-style: disc;
    text-align: left;
    font-size: 21px;
    font-weight: bold;
    word-break: break-word;
}

.MoreDetailsul li span {
    font-weight: 100;
    word-break: break-word;
}

.linecolor2 {
    margin-top: 20px;
    word-break: break-word;
}

.email {
    text-decoration: underline;
}

.el-dialog {
    width: 30%;
}

.examplediv img {
    max-width: 500px;
    object-fit: cover;
}

/* 手机屏幕的字体大小 */
@media screen and (max-width: 900px) {
    .contanctdiv {
        width: 100%;
        margin: 0;
        left: 0;
    }

    .Contacttext {
        width: 100%;
    }


    .Account1,
    .Account2,
    .Details,
    .information {
        width: 100%;
    }

    .submissions,
    .Account {
        width: 95%;
    }

    .Account1 {
        padding: 0;
    }

    .Details {
        border: none;
    }

    .examplediv {
        width: 90%;
    }

    .submissions2 {
        justify-content: center
    }

    .examplediv img {
        width: 100%;
        object-fit: cover;
    }

    .short_p6 {
        display: block;
        margin: 15px auto;
    }

    .doudiv {
        margin: 15px auto;
    }

    .linecolor {
        display: none;
    }

    .el-dialog {
        width: 100%;
    }
}
</style>