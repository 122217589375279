import axios from "axios";
import router from "../router";
// 导入loading Loading 服务
import { Message } from "element-ui";
import VueI18n from "vue-i18n";
import i18n from "../lang";
// import { BASE_URL, TIME_OUT } from "./config"; //不同环境的请求配置
export function request(config) {
  const service = axios.create({
    baseURL: "https://ruthineducation.co.uk/api", //配置公共接口
    // baseURL: "http://10.10.25.119:8080", //配置公共接口
    timeout: 10000, //配置请求超时时间
  });
  // 请求拦截器
  service.interceptors.request.use(
    (config) => {
      //这里可以做token 设置
      const token = localStorage.getItem("token");
      // config.headers["Authorization"] =
      //   "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjIwNjI1MjRiLWE2NjAtNDAyMC04MWJlLWVlNGExNjUyODMxNyJ9.Qp0jdtz2Cpd-hR3oOBzvnNsy4H63i-Tmcpp6e5f8hrzPeaiYdONb6QQ1qD-RheVS2aXVxuuUSw6n8_uJRs-CTA";
      config.headers["Authorization"] = token;
      return config;
    },
    (err) => {}
  );
  console.log(i18n.t("ChangePassword.msg12"));
  // 响应拦截器
  service.interceptors.response.use(
    (res) => {
      if (res.data.code === 200) {
        // Message({
        //   message: "请求成功",
        //   type: "success",
        //   center: true,
        // });
      } else if (res.data.code === 401) {
        Message({
          message: "401",
          type: "warning",
          center: true,
        });
        location.href = "https://new.ruthineducation.co.uk/#/login";
      } else if (res.data.code == 3002) {
        Message({
          message: i18n.t("forgot.msg12"),
          type: "error",
          center: true,
        });
      } else if (res.data.code == 3003) {
        Message({
          message: i18n.t("forgot.msg13"),
          type: "error",
          center: true,
        });
      } else if (res.data.code == 3001) {
        Message({
          message: i18n.t("forgot.msg14"),
          type: "error",
          center: true,
        });
      } else if (res.data.code == 3007) {
        Message({
          message: i18n.t("forgot.msg24"),
          type: "error",
          center: true,
        });
      } else if (res.data.code == 3006) {
        Message({
          message: i18n.t("forgot.msg15"),
          type: "error",
          center: true,
        });
      } else {
        Message({
          message: res.data.code,
          type: "error",
          center: true,
        });
      }
      console.log(res);
      return res;
    },
    (err) => {
      console.log(err);
      Message({
        message: "Network Error",
        type: "error",
        center: true,
      });
      return err;
    }
  );
  return service(config);
}
