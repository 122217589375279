<template>
    <div class="">
        <head_Menu :parentmsg="2"></head_Menu>
        <div class="schooldiv">
            <div class="schooldiv1">
                <p class="schoolp1 animate__animated animate__fadeInRight animate__slow">{{ $t('RuthinSchool.msg1') }}</p>
                <p class="schoolp2 animate__animated animate__fadeInRight animate__slow">{{ $t('RuthinSchool.msg2') }}</p>
                <p class="schoolp3 animate__animated animate__fadeInRight animate__slow">{{ $t('RuthinSchool.msg3') }}
                </p>
                <p class=" schoolp3 animate__animated animate__fadeInRight animate__slow" v-html="$t('RuthinSchool.msg4')">
                </p>
                <p class=" schoolp3 animate__animated animate__fadeInRight animate__slow" v-html="$t('RuthinSchool.msg20')">
                </p>
                <i class="schoolp4 animate__animated animate__fadeInRight animate__slow"><a href="
                https://ruthinschool.co.uk/" target="_blank">{{ $t('RuthinSchool.msg5')
                }}</a></i>
            </div>
            <div class="schooldiv2">
                <img src="../assets/static/13.png" alt="">
            </div>
        </div>
        <div class="Highlights">
            {{ $t('RuthinSchool.msg6') }}
        </div>
        <div class="Highlightsdiv">
            <div class="Highlightsdiv1">
                <img src="../assets/static/14.png" alt="">
                <div class="rightdiv">
                    <p class="Highlightsdivp1 animate__animated animate__fadeInRight animate__slow">
                        {{ $t('RuthinSchool.msg7') }}</p>
                    <h2 class="Highlightsdivp2 animate__animated animate__fadeInRight animate__slow">{{
                        $t('RuthinSchool.msg8') }}</h2>
                </div>

            </div>
            <div class="Highlightsdiv1">
                <img src="../assets/static/15.png" alt="">
                <div class="rightdiv">
                    <p class="Highlightsdivp1 animate__animated animate__fadeInRight animate__slow">
                        {{ $t('RuthinSchool.msg9') }}</p>
                    <h2 class="Highlightsdivp2 animate__animated animate__fadeInRight animate__slow">{{
                        $t('RuthinSchool.msg10') }}</h2>
                </div>
            </div>
            <div class="Highlightsdiv1">
                <img src="../assets/static/16.png" alt="">
                <div class="rightdiv">
                    <p class="Highlightsdivp1 animate__animated animate__fadeInRight animate__slow">
                        {{ $t('RuthinSchool.msg11') }}</p>
                    <h2 class="Highlightsdivp2 animate__animated animate__fadeInRight animate__slow">{{
                        $t('RuthinSchool.msg12') }}</h2>
                </div>
            </div>
            <div class="Highlightsdiv1">
                <img src="../assets/static/17.png" alt="">
                <div class="rightdiv">
                    <p class="Highlightsdivp1 animate__animated animate__fadeInRight animate__slow">
                        {{ $t('RuthinSchool.msg13') }}</p>
                    <h2 class="Highlightsdivp2 animate__animated animate__fadeInRight animate__slow">{{
                        $t('RuthinSchool.msg14') }}</h2>
                </div>
            </div>
        </div>
        <div class="allthree">
            <div class="three">
                <img class="threeimg" src="../assets/svg/time.svg" alt="">
                <p class="num">740</p>
                <p class="threep" v-html="$t('RuthinSchool.msg15')"></p>
            </div>
            <div class="three">
                <img class="threeimg" src="../assets/svg/person.svg" alt="">
                <p class="num">
                    50%</p>
                <p class="threep" v-html="$t('RuthinSchool.msg16')"></p>
            </div>
            <div class="three">
                <img class="threeimg" src="../assets/svg/abc.svg" alt="">
                <p class="num">20+</p>
                <p class="threep" v-html="$t('RuthinSchool.msg17')"></p>
            </div>
            <div class="three1">
                <img class="threeimg" src="../assets/svg/glod.svg" alt="">
                <p class="num">
                    24/7</p>
                <p class="threep" v-html="$t('RuthinSchool.msg18')"></p>
            </div>
            <div class="three1">
                <img class="three1img" src="../assets/static/18.png" alt="">
            </div>
            <div class="three1">
                <img class="threeimg" src="../assets/svg/abc.svg" alt="">
                <p class="num">​♾️</p>
                <p class="threep" v-html="$t('RuthinSchool.msg19')"></p>
            </div>
        </div>
        <!-- <div class="FAQdiv">
            <div class="leftsearch">
                <p class="FAQp1">FAQ</p>
                <div class="FAQp2" v-if="!iptshow">
                    <p class="FAQp3">Frequent Questions</p>
                    <img class="FAQp4" @click="openipt" src="../assets/svg/searah.svg" alt="">
                </div>
                <div v-if="iptshow" class="ipt animate__animated animate__fadeInRight animate__slow">
                    <img class="iptopenimg" src="../assets/svg/searah.svg" alt="">
                    <input type="text" v-model="searchipt" @input="handleInput">
                    <img class="iptcloseimg" @click="openipt" src="../assets/svg/close.svg" alt="">
                </div>
                <div class="FAQp5" v-if="idx != 2">
                    <span class="FAQp6" :class="idx == index ? 'FAQp6active' : ''" v-for="(item, index) in items"
                        :key="item.b" @click="changetab(index)">{{ item.a
                        }}
                    </span>
                </div>
                <div v-if="idx == 0">
                    <div class="eachsearch" v-for="(item, index) in searchlist" :key="index" @click="handletab(index)">
                        <div class="svgimgtitle">
                            <span>{{ item.text }}</span>
                            <img class="openclass" src="../assets/svg/open.svg" alt="">
                        </div>
                        <div v-show="item.show">
                            <p class="textsearch">{{ item.con }}</p>
                            <div class="svgimgs">
                                <img src="../assets/svg/facebook.svg" alt="">
                                <img src="../assets/svg/x.svg" alt="">
                                <img src="../assets/svg/in.svg" alt="">
                                <img src="../assets/svg/link.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="idx == 1">
                    <div class="eachsearch" v-for="(item, index) in seachsearch2" :key="index" @click="handletab2(index)">
                        <div class="svgimgtitle">
                            <span>{{ item.text }}</span>
                            <img class="openclass" src="../assets/svg/open.svg" alt="">
                        </div>
                        <div v-show="item.show">
                            <p class="textsearch" v-html="item.con"></p>
                            <div class="svgimgs">
                                <img src="../assets/svg/facebook.svg" alt="">
                                <img src="../assets/svg/x.svg" alt="">
                                <img src="../assets/svg/in.svg" alt="">
                                <img src="../assets/svg/link.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="idx == 2">
                    <div class="eachsearch" v-for="(item, index) in result" :key="index" @click="handletab3(index)">
                        <div class="svgimgtitle">
                            <span>{{ item.text }}</span>
                            <img class="openclass" src="../assets/svg/open.svg" alt="">
                        </div>
                        <div v-show="item.show">
                            <p class="textsearch" v-html="item.con"></p>
                            <div class="svgimgs">
                                <img src="../assets/svg/facebook.svg" alt="">
                                <img src="../assets/svg/x.svg" alt="">
                                <img src="../assets/svg/in.svg" alt="">
                                <img src="../assets/svg/link.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rightimg">
                <img class="rimg" src="../assets/static/19.png" alt="">
                <img class="rimg1" src="../assets/static/23.png" alt="">
            </div>
        </div> -->
        <Footpage></Footpage>
    </div>
</template>
<script>
export default {
    name: 'RuthinSchool',
    data() {
        return {
            msg: '头部',
            iptshow: false,
            idx: 0,
            items: [{
                a: 'General',
                b: 1
            }, {
                a: 'Setting up FAQs',
                b: 2
            }],
            searchlist: [
                {
                    show: true,
                    text: 'What is an FAQ section?',
                    con: 'An FAQ section can be used to quickly answer common questions about your business like "Where do you ship to?", "What are your opening hours?", or "How can I book a service?". '
                },
                {
                    show: false,
                    text: 'What is an FAQ section?',
                    con: 'FAQs are a great way to help site visitors find quick answers to common questions about your business and create a better navigation experience.'
                },
                {
                    show: false,
                    text: 'Where can I add my FAQs?',
                    con: 'FAQs can be added to any page on your site or to your Wix mobile app, giving access to members on the go.'
                },
            ],
            seachsearch2: [
                {
                    show: true,
                    text: 'How do I add a new question & answer?',
                    con: 'To add a new FAQ follow these steps:<br> 1. Manage FAQs from your site dashboard or in the Editor <br> 2. Add a new question & answer <br> 3. Assign your FAQ to a category <br>4. Save and publish.<br> You can always come back and edit your FAQs.<br>'
                },
                {
                    show: false,
                    text: 'Can I insert an image, video, or GIF in my FAQ?',
                    con: 'Yes. To add media follow these steps:<br>1. Manage FAQs from your site dashboard or in the Editor<br>2. Create a new FAQ or edit an existing one<br>3. From the answer text box click on the video, image or GIF icon <br> 4. Add media from your library and save. '
                },
                {
                    show: false,
                    text: "How do I edit or remove the 'Frequently Asked Questions' title?",
                    con: "You can edit the title from the FAQ 'Settings' tab in the Editor.<br>To remove the title from your mobile app go to the 'Site & App' tab in your Owner's app and customize."
                }
            ],
            allsearchlist: [
                {
                    show: true,
                    text: 'What is an FAQ section?',
                    con: 'An FAQ section can be used to quickly answer common questions about your business like "Where do you ship to?", "What are your opening hours?", or "How can I book a service?". '
                },
                {
                    show: false,
                    text: 'What is an FAQ section?',
                    con: 'FAQs are a great way to help site visitors find quick answers to common questions about your business and create a better navigation experience.'
                },
                {
                    show: false,
                    text: 'Where can I add my FAQs?',
                    con: 'FAQs can be added to any page on your site or to your Wix mobile app, giving access to members on the go.'
                }, {
                    show: false,
                    text: 'How do I add a new question & answer?',
                    con: 'To add a new FAQ follow these steps:<br> 1. Manage FAQs from your site dashboard or in the Editor <br> 2. Add a new question & answer <br> 3. Assign your FAQ to a category <br>4. Save and publish.<br> You can always come back and edit your FAQs.<br>'
                },
                {
                    show: false,
                    text: 'Can I insert an image, video, or GIF in my FAQ?',
                    con: 'Yes. To add media follow these steps:<br>1. Manage FAQs from your site dashboard or in the Editor<br>2. Create a new FAQ or edit an existing one<br>3. From the answer text box click on the video, image or GIF icon <br> 4. Add media from your library and save. '
                },
                {
                    show: false,
                    text: "How do I edit or remove the 'Frequently Asked Questions' title?",
                    con: "You can edit the title from the FAQ 'Settings' tab in the Editor.<br>To remove the title from your mobile app go to the 'Site & App' tab in your Owner's app and customize."
                }
            ],
            result: [],
            searchipt: ''
        };
    },
    methods: {
        handleInput() {
            console.log(this.searchipt)
            let that = this
            let arr = that.allsearchlist
            that.idx = 2
            if (this.searchipt) {
                that.result = arr.filter(item => item.text.indexOf(that.searchipt) > -1);
                // console.log(result)
            } else {
                that.result = arr
            }

        },
        changetab(item) {
            this.idx = item
        },
        handletab(idx) {
            this.searchlist.map((item, index) => {
                if (index == idx) {
                    item.show = !item.show
                } else {
                    item.show = false
                }
            })
        },
        handletab2(idx) {
            this.seachsearch2.map((item, index) => {
                if (index == idx) {
                    item.show = !item.show
                } else {
                    item.show = false
                }
            })
        },
        handletab3(idx) {
            this.result.map((item, index) => {
                if (index == idx) {
                    item.show = !item.show
                } else {
                    item.show = false
                }
            })
        },
        openipt() {
            console.log(123)
            this.iptshow = !this.iptshow;
            this.searchipt = ''
            this.idx = 0
        }
    }
}
</script>
<style scoped>
.schooldiv {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
}

.schooldiv1,
.schooldiv2 {
    width: 50%;
    min-height: 490px;
}

.schooldiv2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.schooldiv1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 8%;
    text-align: left;
    color: #000;
    background: rgb(224, 229, 230);
    padding-bottom: 20px;
}

.schoolp1 {
    font-size: 55px;
    font-weight: bold;
    word-break: break-word;
}

.schoolp2 {
    font-size: 36px;
    font-weight: bold;
    margin: 20px 0;
}

.schoolp3 {
    font-size: 16px;
    line-height: 32px;
    word-break: break-word;
}

.schoolp4 {
    cursor: pointer;
    color: rgb(129, 150, 153);
    font-size: 17px;
    font-style: italic;
    text-decoration: underline;
    margin-top: 20px;
}

.Highlights {
    text-align: center;
    font-size: 55px;
    font-weight: bold;
    color: #000;
    margin: 15px 0;
}

.Highlightsdiv1 {
    width: 639px;
    min-height: 282px;
    color: #000;
    display: flex;
    margin: 10px;

}

.Highlightsdiv {
    /* width: 80%; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Highlightsdiv1:nth-child(1) {
    background: rgb(224, 229, 230);
    /* margin-bottom: 40px; */
}

.Highlightsdiv1:nth-child(2) {
    background: rgb(167, 178, 159);
}

.Highlightsdiv1:nth-child(3) {
    background: rgb(228, 224, 217);
}

.Highlightsdiv1:nth-child(4) {
    background: rgb(192, 203, 204);
}

.Highlightsdiv1 img {
    width: 310px;
    height: 282px;
    /* float: left;
    margin-right: 15px; */
}

.Highlightsdivp1 {
    text-align: left;
    line-height: 25px;
    margin-top: 15px;
    font-size: 15px;
    word-break: break-word;
}

.Highlightsdivp2 {
    /* margin-top: 120px; */
    text-align: left;
    box-sizing: border-box;
    padding-bottom: 20px;
    word-break: break-word;
}

.rightdiv {
    display: flex;
    box-sizing: border-box;
    padding: 0 15px;
    justify-content: space-between;
    flex-direction: column;
}

.allthree {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 40px auto;
    /* margin-bottom: 40px; */
}

.three {
    width: 33.33%;
    height: 369px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: rgb(224, 229, 230);
    border: 1px solid #919191;
    box-sizing: border-box;
}

.three1 {
    width: 33.33%;
    box-sizing: border-box;
    height: 369px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: rgb(224, 229, 230);
    border: 1px solid #919191;
}

.three:nth-child(1) {}

.three:nth-child(2) {
    border-right: none;
    border-left: none;
}

.three:nth-child(3) {}

.threeimg {
    width: 76px;
    height: 76px;
    opacity: 0.5;
}

.num {
    font-size: 85px;
    font-weight: bold;
    color: #000;
    margin: 20px 0;
    position: relative;
}

.num span {
    position: absolute;
    font-size: 28px;
    top: 15px;
}

.threep {
    color: rgb(129, 150, 153);
    font-size: 17px;
}

.three1img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.FAQdiv {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 40px;
}

.leftsearch,
.rightimg {
    width: 50%;
    text-align: left;
    min-height: 638px;
}

.leftsearch {
    background: rgb(224, 229, 230);
    box-sizing: border-box;
    padding: 20px 8%;
}

.rightimg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.FAQp1 {
    font-size: 42px;
    color: #000;
    font-weight: bold;
}

.FAQp2 {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    color: rgb(129, 150, 153);
    font-size: 35px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
}

.FAQp4 {
    width: 24px;
    height: 24px;
    opacity: 0.5;
    margin-left: 40px;
}



.FAQp6,
.FAQp7 {
    margin-right: 30px;
    font-size: 15px;
    font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
    color: rgb(129, 150, 153) !important;
    cursor: pointer;
}

.FAQp6active {
    color: rgb(129, 150, 153) !important;
    font-weight: bold;
}

.FAQp7 {}

.svgimgtitle {
    display: flex;
    justify-content: space-between;
    color: rgb(129, 150, 153);
    font-size: 20px;
    margin-bottom: 20px;
}

.svgimgs img {
    opacity: 0.5;
}

.eachsearch {
    cursor: pointer;
    box-sizing: border-box;
    padding: 30px 0;
    border-bottom: 1px solid rgb(129, 150, 153);
}

.textsearch {
    font-size: 16px;
    color: rgb(129, 150, 153) !important;
    margin-bottom: 10px;
}

.openclass {
    transform: rotate(180deg);
    opacity: 0.5;
}

.ipt {
    width: 100%;
    position: relative;
    /* box-shadow: #72b7ff; */
    margin-bottom: 20px;
    margin-top: 20px;
    box-shadow: 0px 0px 5px 2px #72b7ff;
}

.ipt input {
    border: 1px solid #72b7ff;
    height: 40px;
    line-height: 40px;
    width: 100%;

    box-sizing: border-box;
    padding: 0 40px;
}

.iptopenimg {
    width: 24px;
    height: 24px;
    opacity: 0.5;
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
}

.iptcloseimg {
    cursor: pointer;
    width: 24px;
    height: 24px;
    opacity: 0.5;
    position: absolute;
    top: 10px;
    right: 10px;
}

.rimg {
    display: block;
}

.rimg1 {
    display: none;
}

/* 手机屏幕的字体大小 */
@media screen and (max-width: 900px) {
    .schooldiv {
        width: 100%;
        flex-direction: column-reverse;
    }

    .schooldiv1,
    .schooldiv2 {
        width: 100%;
        height: auto;
    }

    .schooldiv2 {
        height: 247px;
    }

    .schoolp1 {
        margin-top: 15px;
        font-size: 27px;
    }

    .schoolp2 {
        font-size: 22px;
    }

    .schoolp4 {
        margin-bottom: 15px;
    }

    .Highlights {
        font-size: 30px;
    }

    .Highlightsdiv1 {
        width: 100%;
        display: block;
        height: auto;
        margin: 0;
        margin-bottom: 30px;
    }

    .Highlightsdiv1 img {
        width: 100%;
        object-fit: cover;
    }

    .Highlightsdivp2 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .allthree {
        width: 100%;
    }

    .three1,
    .three {
        width: 100%;
    }

    .three:nth-child(2) {
        border: 1px solid #919191;
    }

    .FAQdiv {
        width: 100%;
        flex-direction: column-reverse;
    }

    .leftsearch,
    .rightimg {
        width: 100%;
        text-align: left;
        min-height: auto;
    }

    .FAQp1 {
        font-size: 26px;
    }

    .FAQp3 {
        font-size: 20px;
    }

    .rightimg {
        height: 247px;
    }

    .rimg {
        display: none;
    }

    .rimg1 {
        display: block;
    }
}
</style>