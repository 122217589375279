<template>
    <div class="all">
        <head_Menu :parentmsg="3"></head_Menu>


        <div class="Talentdiv">
            <img class="Talentdiv1 Talentdivimg" src="../assets/static/51.png" alt="">
            <span class="Talentdiv2 animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg1')
            }}</span>
            <span class="Talentdiv3" @click="goToTarget">{{ $t('International.msg11') }}</span>
            <div class="Talentdiv4">
                <img class="Talentdiv4img" src="../assets/svg/hua.svg" alt="">
                <p class="Talentdiv5">
                    <span v-html="$t('TalentAward.msg30')"></span>
                    <span class="Talentdiv6" v-html="$t('TalentAward.msg35')"></span>
                </p>
            </div>
        </div>
        <div class="mobileTalentdiv">
            <img class="Talentdiv1" src="../assets/static/51.png" alt="">
        </div>
        <div class="minutediv">
            <p class="minutediv1 animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg31') }}</p>
            <div class="cutdowndiv animate__animated animate__fadeInRight animate__slow">
                <count-down v-on:start_callback="countDownS_cb(1)" v-on:end_callback="countDownE_cb(1)"
                    :currentTime=Number(startTime) :startTime=Number(startTime) :endTime=Number(endTime)
                    :endText="'结束自定义文字2'" :dayTxt="'Days'" :hourTxt="'Hours'" :minutesTxt="'Minutes'"
                    :secondsTxt="'Seconds'"></count-down>
            </div>
            <span class="register" @click="Changetab('/Registration')">{{ $t('TalentAward.msg37') }}</span><br>
            <span class="register" @click="Changetab('/login')">{{ $t('TalentAward.msg38') }}</span>

        </div>
        <div class="Talentcenter">
            <p class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg32') }}</p>
        </div>
        <div class="mobileTalentcenter">
            <img class="Talentdiv1" src="../assets/static/51.png" alt="">
        </div>
        <div class="Talentcenter1 animate__animated animate__fadeInRight animate__slow">
            <p :class="$i18n.locale == 'zn' ? 'zc' : ''">
                {{ $t('TalentAward.msg2') }}
            </p>
            <p :class="$i18n.locale == 'zn' ? 'zc' : ''">
                {{ $t('TalentAward.msg3') }}
            </p>
        </div>
        <div class="missdiv">{{ $t('TalentAward.msg39') }}<br>
            <span class="linkspan" @click="tosubmitwork">{{ $t('TalentAward.msg42') }}{{ $t('TalentAward.msg40')
            }}</span> {{
    $t('TalentAward.msg41') }}
        </div>
        <div class="AwardPrizes" ref="AwardPrizes">
            <div class="AwardPrizes1">
                <p class="AwardPrizes2" v-html="$t('TalentAward.msg33')"></p>
                <p class="AwardPrizes3">—</p>
                <p class="AwardPrizes3">{{ $t('TalentAward.msg34') }} </p>
            </div>
            <div class="AwardPrizes4">
                <div class="AwardPrizes7"></div>
                <p class="AwardPrizes5 animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg4') }}
                </p>
                <ul class="AwardPrizes6">
                    <li class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg5') }}</li>
                    <li class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg6') }}</li>
                </ul>
                <p class="AwardPrizes5 animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg7') }}
                </p>
                <ul class="AwardPrizes6">
                    <li class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg8') }}</li>
                    <li class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg9') }}</li>
                    <li class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg10') }}</li>
                    <li class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg11') }}</li>
                </ul>
                <p class="AwardPrizes5 animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg12') }}
                </p>
                <ul class="AwardPrizes6">
                    <li class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg13') }}</li>
                    <li class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg14') }}</li>
                    <li class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg15') }}</li>
                    <li class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg16') }}</li>
                    <li class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg17') }}</li>
                    <li class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg18') }}</li>
                    <li class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg19') }}</li>
                </ul>
            </div>
        </div>
        <div class="Timeline">
            <div class="Timelinecon">
                <p class="Timeline1 animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg23') }}</p>
                <p class="Timeline1 Timeline1_1 animate__animated animate__fadeInRight animate__slow">{{
                    $t('TalentAward.msg36') }}</p>
                <ul class="Timeline2">
                    <li class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg24') }}</li>
                    <li class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg25') }}</li>
                    <li class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg26') }}</li>
                    <li class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg27') }}</li>
                    <li class="animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg28') }}</li>
                </ul>
                <div class="Timeline3"></div>
                <p class="Timeline4 animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg20') }}</p>
                <p class="Timeline5 animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg21') }}</p>
                <p class="Timeline6 animate__animated animate__fadeInRight animate__slow">{{ $t('TalentAward.msg22') }}</p>
                <div class="Timeline7 animate__animated animate__fadeInRight animate__slow">
                    <img src="../assets/static/54.png" alt="" @mousemove="handlehover" @mouseout="handlehout">
                    <img class="wechatimg" v-if="showimg" src="../assets/static/60.png" alt="">
                    <a href="https://www.instagram.com/ruthinschoolofficial/" target="_blank" rel="noopener noreferrer"><img
                            src="../assets/static/55.png" alt=""></a>
                    <a href="https://twitter.com/RuthinSchool" target="_blank" rel="noopener noreferrer"><img
                            src="../assets/static/56.png" alt=""></a>

                    <a href="https://www.linkedin.com/company/ruthinschool/" target="_blank" rel="noopener noreferrer"><img
                            src="../assets/static/57.png" alt=""></a>

                </div>
            </div>

        </div>
        <Footpage :foot="1"></Footpage>
    </div>
</template>
<script>
import CountDown from '../components/countdown'

export default {
    name: 'HeadMenu',
    components: {
        CountDown
    },
    data() {
        return {
            msg: '头部',
            startTime: '',
            endTime: '',
            showimg: false
        };
    },

    mounted() {
        this.gettime()
        console.log(this.$i18n.locale)
    },
    methods: {
        handlehover() {
            this.showimg = true
        },
        handlehout() {
            this.showimg = false
        },
        tosubmitwork() {
            if (localStorage.getItem("token")) {
                this.$router.push({
                    path: '/SubmitWork'
                })
            } else {
                this.$router.push({
                    path: '/login'
                })
            }
        },
        goToTarget() {
            // 获取目标位置的DOM节点
            const target = this.$refs.AwardPrizes;

            if (target) {
                // 计算目标位置相对于文档顶部的垂直偏移量
                const offsetTop = target.offsetTop - document.documentElement.scrollTop || target.offsetTop;

                // 平滑地滚动到目标位置
                window.scroll({ top: offsetTop, behavior: 'smooth' });
            } else {
                console.error('未找到目标位置');
            }
        },
        Changetab(item) {
            this.$router.push({
                path: item
            })
        },
        gettime() {
            // 创建一个新的Date对象
            var currentTime = new Date();
            // 将Date对象转换为时间戳（单位为毫秒）
            this.startTime = currentTime.getTime()
            var date = new Date('2024-05-12 12:00:00'); // 将 '2021-09-30' 替换成需要获取时间戳的日期字符串
            // 调用 getTime() 方法获取该日期的时间戳（单位为毫秒）
            this.endTime = date.getTime() - 25200000;//跟英国相差8小时
        },
        countDownS_cb: function (x) {
            console.log(x)
        },
        countDownE_cb: function (x) {
            console.log(123123)
        }
    }
}
</script>
<style scoped>
.zc {
    word-break: normal !important;
}

.Talentdivimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.missdiv {
    width: 867px;
    margin: 0 auto;
    font-size: 56px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 15px;
    position: relative;
}

.linkspan {
    cursor: pointer;
}

.linkspan::after {
    content: '';
    position: absolute;
    width: 190px;
    height: 4px;
    bottom: -4px;
    left: -2px;
    background: #000;
}

.wechatimg {
    margin-top: 15px;
}

.mobileTalentdiv {
    display: none;
}

.Talentdiv {
    display: block;
    width: 1200px;
    height: 418px;
    margin: 0 auto;
    position: relative;
}

.Talentdiv img {
    width: 100%;

}

.Talentdiv2 {
    position: absolute;
    font-size: 77px;
    font-weight: bold;
    color: #fff;
    bottom: 100px;
    left: 70px;
}

.Talentdiv3 {
    position: absolute;
    letter-spacing: 1px;
    display: inline-block;
    width: 445px;
    height: 68px;
    line-height: 68px;
    background: #000;
    color: #fff;
    text-align: center !important;
    font-size: 15px;
    cursor: pointer;
    transition: all 0.2s ease, visibility 0s;
    border: 1px solid #000;
    bottom: -34px;
    left: 70px;
}

.register {
    letter-spacing: 1px;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 15px;
    /* width: 445px; */
    height: 58px;
    line-height: 58px;
    background: #000;
    color: #fff;
    text-align: center !important;
    font-size: 25px;
    cursor: pointer;
    transition: all 0.2s ease, visibility 0s;
    border: 1px solid #000;
    margin-bottom: 30px;
}

.Talentdiv4 {
    position: absolute;
    overflow: hidden;
    width: 210px;
    height: 210px;
    right: 70px;
    bottom: -105px;
    /* background-color: #1F4472; */
    /* background-image; */
    /* background: url(../assets/svg/hua.svg) no-repeat; */
    /* background-size: cover; */
    /* fill: #1F4472; */
}

.Talentdiv4img {
    width: 210px;
    height: 210px;
    position: relative;
    left: -210px;
    border-right: 100px solid transparent;
    filter: drop-shadow(210px 0px 0 #1F4472);
    font-family: caudex, serif;
}

.Talentdiv5 {
    position: absolute;
    font-size: 20px;
    color: #fff;
    top: 60px;
    word-break: break-word;
    text-align: center;
    left: 0;
    right: 0;
    line-height: 32px;
}

.Talentdiv6 {
    color: rgb(237, 210, 146);
}

.minutediv {
    box-sizing: border-box;
    padding-top: 130px;
}

.minutediv1 {
    word-break: break-word;
    color: #000000 !important;
    font-family: cormorantgaramond-light, cormorantgaramond, "cormorant garamond", serif !important;
    font-size: 32px !important;
    text-decoration: none !important;
    text-align: center !important;
    letter-spacing: 0.15em !important;
    line-height: 42px !important;
}

.mobileTalentcenter {
    display: none;
}

.Talentcenter {
    display: block;
    width: 1200px;
    height: 418px;
    margin: 0 auto;
    background: url(../assets/static/51.png) no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    font-size: 45px;
    font-weight: bold;
    color: #fff;
    box-sizing: border-box;
    padding: 40px 10%;
    word-break: break-word;
    text-align: left;
}

.Talentcenter p {
    word-break: break-word;
    line-height: 60px;
}

.Talentcenter1 {
    width: 867px;
    margin: 0 auto;
}

.Talentcenter1 p {
    margin: 40px 0;
    color: #000000 !important;
    font-size: 22px !important;
    text-decoration: none !important;
    text-align: left !important;
    line-height: 40px !important;
    /* font-family: cormorantgaramond-light, cormorantgaramond, cormorant garamond, serif; */
    word-break: break-word;
}

.AwardPrizes {
    margin: 0 auto;
    width: 867px;
    display: flex;
    flex-wrap: wrap;

}

.AwardPrizes1 {
    width: 50%;
    background: url(../assets/static/53.png) no-repeat;
    background-size: cover;
}

.AwardPrizes4 {
    width: 50%;
    box-sizing: border-box;
    padding: 0 30px;
}

.AwardPrizes2 {
    font-size: 56px;
    font-weight: bold;
    color: #fff;
    text-align: left;
    box-sizing: border-box;
    padding: 0 20px;
    margin-top: 60px;
    margin-bottom: 30px;
}

.AwardPrizes3 {
    font-size: 16px;
    color: #fff;
    text-align: left;
    box-sizing: border-box;
    padding: 0 20px;
    margin-bottom: 30px;
}

.AwardPrizes7 {
    width: 100%;
    height: 40px;
    border-bottom: 2px solid #000;
}

.AwardPrizes5 {
    font-size: 26px;
    font-weight: bold;
    color: #000;
    text-align: left;
    margin: 15px 0;
}

.AwardPrizes6 li {
    font-size: 17px;
    line-height: 28px;
    text-align: left;
    list-style: disc;
    word-break: break-word;
}

.Timeline {
    background: #000;
    color: #fff;
}

.Timelinecon {
    width: 700px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 70px 0;
    margin-bottom: 20px;
    margin-top: 5px;
}

.Timeline1 {
    font-size: 56px;
    font-weight: bold;
}

.Timeline2 {
    font-size: 17px;
    text-align: left;
}

.Timeline3 {
    height: 30px;
    border-bottom: 2px dashed rgb(237, 210, 146);
    box-sizing: border-box;
}

.Timeline2 li {
    list-style: disc;
    line-height: 30px;
    word-break: break-word;
}

.Timeline4,
.Timeline5,
.Timeline6,
.Timeline7 {
    text-align: left;
}

.Timeline4 {
    font-size: 38px;
    font-weight: bold;
    margin: 30px 0 50px 0;
    letter-spacing: 2px;
}

.Timeline5,
.Timeline6 {
    font-size: 17px;
}

.Timeline6 {
    margin-bottom: 20px;
    color: rgb(237, 210, 146);
}

.Timeline7 img {
    margin-right: 10px;
    width: 39px;
    height: 39px;
    cursor: pointer;
}

.cutdowndiv {
    width: 560px;
    margin: 30px auto;
    text-align: center;
}

.Timeline1_1 {
    font-size: 30px;
    font-weight: normal;
}

/* 手机屏幕的字体大小 */
@media screen and (max-width: 900px) {
    .cutdowndiv {
        width: 100%;
        margin: 30px auto;
        text-align: center;
    }

    .Talentdiv {
        display: none;
    }

    .mobileTalentdiv {
        display: block;
    }

    .mobileTalentdiv img {
        width: 100%;
    }

    .minutediv {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .mobileTalentcenter {
        display: block;
    }

    .Talentcenter {
        display: none;
    }

    .mobileTalentcenter img {
        width: 100%;
    }

    .Talentcenter1 {
        font-size: 18px !important;
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
    }

    .AwardPrizes,
    .AwardPrizes1,
    .AwardPrizes4 {
        width: 100%;
    }

    .AwardPrizes1 {
        background: url(../assets/static/51.png) no-repeat;
        background-size: cover;
    }

    .Timelinecon {
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
    }

    .Timeline7 {
        text-align: center;
    }

    .Timeline1 {
        font-size: 28px;
    }

    .missdiv {
        font-size: 25px;
        box-sizing: border-box;
        padding: 0 15px;
    }

    .linkspan::after {
        content: '';
        position: absolute;
        width: 80px;
        height: 3px;
        bottom: -4px;
        left: 15px;
        background: #000;
    }

    .all {
        overflow: hidden;
    }
}
</style>