<template>
    <div class="">
        <head_Menu :parentmsg="4"></head_Menu>
        <div class="shortcon">
            <div class="shortdiv">
                <p class="short_p1 animate__animated animate__fadeInRight animate__slow">{{ $t('International.msg1') }} </p>
                <p class="short_p2 animate__animated animate__fadeInRight animate__slow">{{ $t('International.msg2') }}</p>
                <p class="short_p3 animate__animated animate__fadeInRight animate__slow">{{ $t('International.msg3') }}</p>
                <p class="short_p4 animate__animated animate__fadeInRight animate__slow">{{ $t('International.msg4') }}</p>
                <div class="short_p5">
                    <a href="https://ruthinschool.co.uk/summer-camp/" target="_blank" rel="noopener noreferrer"><span
                            class="short_p6"> {{
                                $t('International.msg27') }}</span></a>
                </div>
            </div>

        </div>
        <div class="shortdiv">
            <p class="short_p7 animate__animated animate__fadeInRight animate__slow">{{ $t('International.msg6') }}</p>
            <p class="short_p8 animate__animated animate__fadeInRight animate__slow">{{ $t('International.msg5') }}</p>
        </div>
        <div class="Campsdiv">
            <div class="Campsdiv1">
                <img class="Campsdiv35_img" src="../assets/static/35.png" alt="">
                <div class="Campsdiv2">
                    {{ $t('International.msg7') }}
                </div>
            </div>
            <div class="Campsdiv1">
                <img class="Campsdiv35_img" src="../assets/static/36.png" alt="">
                <div class="Campsdiv2">
                    {{ $t('International.msg8') }}
                </div>
            </div>
            <div class="Campsdiv1">
                <img class="Campsdiv35_img" src="../assets/static/37.png" alt="">
                <div class="Campsdiv2">
                    {{ $t('International.msg10') }}
                </div>
            </div>
            <div class="Campsdiv1">
                <img class="Campsdiv35_img" src="../assets/static/38.png" alt="">
                <div class="Campsdiv2">
                    {{ $t('International.msg9') }}
                </div>
            </div>
            <div class="Campsdiv3">
                <a href="https://ruthinschool.co.uk/summer-camp/" target="_blank" rel="noopener noreferrer"><span
                        class="short_p6">{{ $t('International.msg11') }}</span></a>

            </div>
        </div>
        <div class="titleinclued">
            <p class="titleinclued1">{{ $t('International.msg12') }}</p>
            <p class="titleinclued2">{{ $t('International.msg13') }}</p>
        </div>

        <div class="Summerdiv">
            <div class="Summerdiv1" @click="bigimg(0)">
                <!-- <el-image class="Summerdiv2" :src="require('@/assets/static/39.png')" :preview-src-list="srcList">
                </el-image> -->
                <img class="Summerdiv2" src="../assets/static/39.png" alt="">
                <!-- <div class="mask"></div> -->
                <p class="Summerdiv3">{{ $t('International.msg14') }}</p>
            </div>
            <div class="Summerdiv1" @click="bigimg(1)">
                <img class="Summerdiv2" src="../assets/static/40.png" alt="">
                <!-- <div class="mask"></div> -->
                <p class="Summerdiv3">{{ $t('International.msg15') }}</p>
            </div>
            <div class="Summerdiv1" @click="bigimg(2)">
                <img class="Summerdiv2" src="../assets/static/41.png" alt="">
                <!-- <div class="mask"></div> -->
                <p class="Summerdiv3">{{ $t('International.msg16') }}</p>
            </div>
            <div class="Summerdiv1" @click="bigimg(3)">
                <img class="Summerdiv2" src="../assets/static/42.png" alt="">
                <!-- <div class="mask"></div> -->
                <p class="Summerdiv3">{{ $t('International.msg17') }}</p>
            </div>
            <div class="Summerdiv1" @click="bigimg(4)">
                <img class="Summerdiv2" src="../assets/static/43.png" alt="">
                <!-- <div class="mask"></div> -->
                <p class="Summerdiv3">{{ $t('International.msg18') }}</p>
            </div>
            <div class="Summerdiv1" @click="bigimg(5)">
                <img class="Summerdiv2" src="../assets/static/44.png" alt="">
                <!-- <div class="mask"></div> -->
                <p class="Summerdiv3">{{ $t('International.msg19') }}</p>
            </div>
            <div class="Summerdiv1" @click="bigimg(6)">
                <img class="Summerdiv2" src="../assets/static/45.png" alt="">
                <!-- <div class="mask"></div> -->
                <p class="Summerdiv3"> {{ $t('International.msg20') }}</p>
            </div>
            <div class="Summerdiv1" @click="bigimg(7)">
                <img class="Summerdiv2" src="../assets/static/46.png" alt="">
                <!-- <div class="mask"></div> -->
                <p class="Summerdiv3">{{ $t('International.msg21') }}</p>
            </div>
        </div>
        <div class="Contactdiv">
            <div class="Contactdivcon">
                <p class="Contactdiv1">{{ $t('International.msg22') }}</p>
                <p class="Contactdiv2">{{ $t('International.msg23') }}</p>
                <div class="Contactdiv3">
                    <div class="Contactdiv4">
                        <p class="Contactdiv5">{{ $t('International.msg24') }} </p>
                        <p class="Contactdiv6">{{ $t('International.msg26') }}</p>
                    </div>
                    <div class="Contactdiv4">
                        <p class="Contactdiv5">{{ $t('International.msg25') }} </p>
                        <p class="Contactdiv7">
                            <img class="wechatimg" v-if="showimg" src="../assets/static/60.png" alt="">
                            <img class="showomg" @mousemove="handlehover" @mouseout="handlehout"
                                src="../assets/static/47.png" alt="">
                            <a href="https://twitter.com/RuthinSchool" target="_blank" rel="noopener noreferrer"><img
                                    class="" src="@/assets/svg/Twitter.svg" alt=""></a>
                            <a href="https://www.linkedin.com/company/ruthinschool/" target="_blank"
                                rel="noopener noreferrer"><img class="" src="../assets/static/49.png" alt=""></a>
                            <a href="https://www.instagram.com/ruthinschoolofficial/" target="_blank"
                                rel="noopener noreferrer"> <img class="" src="../assets/static/50.png" alt=""></a>

                        </p>
                    </div>
                </div>
            </div>

        </div>
        <div>
            <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="srcList" />
        </div>
        <Footpage></Footpage>
    </div>
</template>
<script>
//引入el-image-viewer 
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
    name: 'ShortCoursesCamps',
    components: {
        ElImageViewer,//注册
    },
    data() {
        return {
            showimg: false,
            showViewer: false,
            msg: '头部',
            srcList: [require('@/assets/static/39.png'), require('@/assets/static/40.png'), require('@/assets/static/41.png'), require('@/assets/static/42.png'), require('@/assets/static/43.png'), require('@/assets/static/44.png'), require('@/assets/static/45.png'), require('@/assets/static/46.png')]
        };
    },
    methods: {
        handlehover() {
            this.showimg = true
        },
        handlehout() {
            this.showimg = false
        },
        bigimg(idx) {

            let moveCount = this.srcList.length - idx;
            // 使用 splice 函数进行移动操作
            this.srcList.unshift(...this.srcList.splice(idx));
            this.showViewer = true
        },
        closeViewer() { //关闭
            this.showViewer = false
            this.srcList = [require('@/assets/static/39.png'), require('@/assets/static/40.png'), require('@/assets/static/41.png'), require('@/assets/static/42.png'), require('@/assets/static/43.png'), require('@/assets/static/44.png'), require('@/assets/static/45.png'), require('@/assets/static/46.png')]
        },
        Changetab(item) {
            this.$router.push({
                path: item
            })
        }
    }
}
</script>
<style scoped>
.Contactdiv7 {
    position: relative;
}

.wechatimg {
    position: absolute;
    width: 170px !important;
    height: 170px !important;
    top: 30px;
    left: 0;
}

.shortcon {
    width: 90%;
    margin: 0 auto;
    background: radial-gradient(circle at 0% 100%, rgba(146, 60, 84, 0.64) 0%, 24%, rgba(146, 60, 84, 0) 24%), radial-gradient(circle at 3.582356770833333% 36.643880208333336%, #E4E0D9 0%, 35%, rgba(228, 224, 217, 0) 35%), radial-gradient(circle at 27.496744791666668% 92.77994791666667%, #A7B29F 0%, 40%, rgba(167, 178, 159, 0) 40%), radial-gradient(circle at 53.3837890625% 100%, rgba(237, 210, 146, 0.99) 0%, 50%, rgba(237, 210, 146, 0) 50%), radial-gradient(circle at 0.7796223958333334% 0%, #819699 0%, 70%, rgba(129, 150, 153, 0) 70%), radial-gradient(circle at 93.6865234375% 11.42578125%, #E0E5E6 0%, 70%, rgba(224, 229, 230, 0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255, 255, 255, 0) 100%);
}

.shortdiv {
    width: 900px;
    margin: auto;
    text-align: left;
    box-sizing: border-box;
    padding: 50px 0 20px 0;
}

.short_p1 {
    font-size: 31px;
    font-weight: bold;
}

.short_p2 {
    font-size: 40px;
    word-break: break-word;
    font-weight: bold;
}

.short_p3 {
    font-size: 32px;
    font-weight: normal;
}

.short_p4 {
    font-size: 16px;
    font-weight: lighter;
    line-height: 32px;
    word-break: break-word;
}

.short_p5 {
    margin-top: 10px;
}

.short_p5 span {
    letter-spacing: 1px;
    display: inline-block;
    width: 228px;
    height: 40px;
    line-height: 40px;
    background: #2d2d2d;
    color: #fff;
    text-align: center !important;
    cursor: pointer;
    transition: all 0.2s ease, visibility 0s;
    border: 1px solid #2d2d2d;
}

.short_p5 span:hover {
    background: #fff;
    border: 1px solid #819699;
    color: #819699;
}

.Campsdiv3 {
    /* margin-top: 10px; */
    width: 100%;
    position: relative;
    box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 100px;
}

.Campsdiv3 span {
    letter-spacing: 1px;
    display: inline-block;
    width: 228px;
    height: 40px;
    line-height: 40px;
    background: #2d2d2d;
    color: #fff;
    text-align: center !important;
    cursor: pointer;
    transition: all 0.2s ease, visibility 0s;
    border: 1px solid #2d2d2d;
}

.Campsdiv3 span:hover {
    background: #fff;
    border: 1px solid #819699;
    color: #819699;
}

.short_p7 {
    font-size: 56px;
    font-weight: bold;
    margin-bottom: 20px;
}

.short_p8 {
    line-height: 32px;
    word-break: break-word;
}

.Campsdiv {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgb(224, 229, 230);
    box-sizing: border-box;
    padding: 30px 0;
}

.Campsdiv1 {
    width: 286px;
    min-height: 393px;
    /* margin: 0 10px; */
    position: relative;
}

.Campsdiv1 img {
    width: 100%;
    height: 335px;
    object-fit: cover;
}

.Campsdiv1:nth-child(1),
.Campsdiv1:nth-child(2),
.Campsdiv1:nth-child(3) {
    margin-right: 30px;
}

.Campsdiv2 {
    position: relative;
    background: #fff;
    width: 276px;
    box-sizing: border-box;
    padding: 30px 10px 10px 10px;
    top: -20px;
    right: -20px;
    font-weight: bold;
    text-align: left;
}

.titleinclued1 {
    font-size: 56px;
    color: rgb(129, 150, 153);
    font-weight: bold;
    margin-bottom: 20px;
}

.titleinclued2 {
    font-size: 32px;
}

.titleinclued {
    box-sizing: border-box;
    padding: 50px 0;
}

.Summerdiv {
    width: 980px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.Summerdiv1 {
    position: relative;
    display: inline-block;
    width: 230px;
    background: rgba(129, 150, 153, 1);
    margin-bottom: 30px;
}

.Summerdiv1::after {
    content: "";
    /* 必需属性，表示生成内容为空 */
    position: absolute;
    height: 230px;
    /* 设置遮罩层为绝对定位 */
    top: 0;
    /* 距离顶部边界为0 */
    left: 0;
    /* 距离左边界为0 */
    right: 0;
    /* 距离右边界为0 */
    /* bottom: 0; */
    /* 距离底部边界为0 */
    background-color: rgba(255, 255, 255, 0.5);
    /* 设置透明度为0.5的黑色作为遮罩层背景色 */
    z-index: 999;
    /* 设置遮罩层在其他元素之上 */
    opacity: 0;
    /* 初始状态不可见 */
    transition: opacity 0.3s ease;
    /* 过渡效果，平滑地改变透明度 */
}

.Summerdiv1:hover::after {
    opacity: 1;
    /* 鼠标移入后遮罩层可见 */
}

.mask {
    position: absolute;
    width: 230px;
    height: 230px;
    top: -0;
    background-color: rgba(255, 255, 255, 0.5);
    /* 设置背景色及透明度 */
    opacity: 0;
    /* 初始状态不显示 */
    transition: opacity 0.2s ease-in-out;
    /* 过渡效果 */
    /* transition: transform 0.5s ease; */
}

.Summerdiv1:hover .Summerdiv2 {
    transform: scale(0.98);
}

.Summerdiv1:hover .mask {
    opacity: 1;
    /* transform: translateY(-100%); */
}

.Summerdiv2 {
    transition: transform 0.5s ease;
    width: 230px;
    height: 230px;
    object-fit: cover;
}

.Summerdiv3 {
    color: #fff;
    box-sizing: border-box;
    padding: 40px 5px;
    font-size: 20px;
    font-family: math;
    text-align: center;
    word-break: break-word;
}

.Contactdiv {
    background: rgb(192, 203, 204);
    box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 0;
}

.Contactdivcon {
    width: 980px;
    margin: 0 auto;
    background: #fff;
    box-sizing: border-box;
    padding: 30px;
}

.Contactdiv3 {
    display: flex;
    flex-wrap: wrap;
}

.Contactdiv4 {
    width: 50%;
}

.Contactdiv1 {
    font-size: 16px;
    color: rgb(167, 178, 159);
    font-weight: bold;
    margin-bottom: 15px;
}

.Contactdiv2 {
    font-size: 30px;
    color: #000;
    font-weight: bold;
    margin-bottom: 15px;
}

.Contactdiv5 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
}

.Contactdiv6 {
    font-size: 16px;
    color: rgb(129, 150, 153);
}

.Contactdiv7 img {
    margin: 0 5px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

/* 手机屏幕的字体大小 */
@media screen and (max-width: 900px) {
    .shortcon {
        width: 100%;
    }

    .shortdiv {
        width: 100%;
        box-sizing: border-box;
        box-sizing: border-box;
        padding: 20px;
    }

    .Campsdiv1:nth-child(1) {
        margin-top: 0 !important;
    }

    .Campsdiv1 {
        width: 260px;
        /* height: 250px; */
    }

    .Campsdiv {
        width: 100%;
    }

    .Campsdiv1:nth-child(1),
    .Campsdiv1:nth-child(2),
    .Campsdiv1:nth-child(3),
    .Campsdiv1:nth-child(4) {
        margin-right: 0px;
        /* margin-top: 100px; */
    }

    .Campsdiv2 {
        top: -5px;
    }

    .Summerdiv {
        width: 100%;
    }

    .Summerdiv1 {
        width: 100%;
    }

    .Summerdiv1 img {
        width: 100%;
        height: auto;
    }

    .Summerdiv1:hover .mask {
        opacity: 0;
        /* transform: translateY(-100%); */
    }

    .Contactdivcon {
        width: 90%;
    }

    .Contactdiv4 {
        width: 100%;
    }

    .Contactdiv {
        margin-bottom: 20px;
    }
}
</style>