<template>
    <div class="admindiv">
        <div style="text-align: right;">
            <el-button @click="logout" type="primary">{{ $t('SubmitWork.msg3') }}</el-button>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="用户管理" name="first">
                <el-table v-loading="loading" :data="userList">
                    <el-table-column label="用户编号" align="center" key="userId" prop="userId" />
                    <el-table-column label="国籍" align="center" key="country" prop="country" />
                    <el-table-column label="用户名称" align="center" key="userName" prop="userName"
                        :show-overflow-tooltip="true" />
                    <el-table-column label="用户昵称" align="center" key="nickName" prop="nickName"
                        :show-overflow-tooltip="true" />
                    <el-table-column label="手机号码" align="center" key="phonenumber" prop="phonenumber" width="120" />

                </el-table>
                <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                    :limit.sync="queryParams.pageSize" @pagination="getlistUser" />
            </el-tab-pane>
            <el-tab-pane label="作品管理" name="second">
                <el-table v-loading="loading" :data="jobLogList">
                    <el-table-column label="作品编号" width="80" align="center" prop="artId" />
                    <el-table-column label="学生姓名" align="center" prop="studentName" :show-overflow-tooltip="true" />
                    <el-table-column label="邮箱" align="center" prop="email" />
                    <el-table-column label="年级" align="center" prop="grade" :show-overflow-tooltip="true" />

                    <el-table-column label="学生年龄" align="center" prop="studentAge" :show-overflow-tooltip="true" />
                    <el-table-column label="作品标题" align="center" prop="artName" :show-overflow-tooltip="true" />
                    <el-table-column label="描述" align="center" prop="artDescription" :show-overflow-tooltip="true" />
                    <el-table-column label="作品材料" align="center" prop="specialRemark" :show-overflow-tooltip="true" />
                    <!-- draftFlag -->
                    <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                        <template slot-scope="scope">
                            {{ scope.row.draftFlag == 1 ? '已入围' : '未入围' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                        <template slot-scope="scope">
                            <el-button size="mini" type="text" icon="el-icon-view"
                                @click="handleView(scope.row)">详细</el-button>
                            <el-button size="mini" type="text" @click="handleView(scope.row)">审核</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                    :limit.sync="queryParams.pageSize" @pagination="getuserDetail" />
            </el-tab-pane>
        </el-tabs>

        <el-dialog title="作品" :visible.sync="open" width="700px" append-to-body>
            <el-form ref="form" :model="form" label-width="150px" size="mini">
                <el-row>
                    <el-col :span="12">
                        <el-form-item :label="$t('SubmitWork.msg5')">{{ form.studentName }}</el-form-item>
                        <el-form-item :label="$t('SubmitWork.msg6')">{{ form.grade }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <!-- <el-form-item label="年级：">{{ form.grade }}</el-form-item> -->
                        <el-form-item :label="$t('SubmitWork.msg7')">{{ form.studentAge }}</el-form-item>
                        <el-form-item :label="$t('SubmitWork.msg8')">{{ form.artName }}</el-form-item>
                    </el-col>
                    <el-col :span="12">

                        <el-form-item :label="$t('SubmitWork.msg10')">{{ form.artDescription }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('SubmitWork.msg13')">{{ form.specialRemark }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('forgot.msg16')">{{ form.lengthval }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('forgot.msg17')">{{ form.widthval }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('forgot.msg18')">{{ form.heightval }}</el-form-item>
                        <el-form-item :label="$t('login.msg4')">{{ form.email }}</el-form-item>
                        <!-- <el-form-item :label="$t('SubmitWork.msg15')">
                            <el-select :disabled=true v-model="form.size" :placeholder="$t('SubmitWork.msg15')">
                                
                                <el-option :label="$t('SubmitWork.msg30')" value="1"></el-option>
                                <el-option :label="$t('SubmitWork.msg31')" value="2"></el-option>
                                <el-option :label="$t('SubmitWork.msg32')" value="3"></el-option>
                                <el-option :label="$t('SubmitWork.msg33')" value="4"></el-option>
                                <el-option :label="$t('SubmitWork.msg34')" value="5"></el-option>
                            </el-select>
                        </el-form-item> -->
                    </el-col>
                    <el-col :span="24">
                        <el-form-item :label="$t('SubmitWork.msg12')">
                            <el-checkbox-group :disabled=true v-model="form.artType">
                                <!-- <el-checkbox v-for="city in cities" :label="city" :key="city">{{ city }}</el-checkbox> -->
                                <el-checkbox label="1">{{
                                    $t("SubmitWork.msg35")
                                }}</el-checkbox>
                                <el-checkbox label="2">{{
                                    $t("SubmitWork.msg36")
                                }}</el-checkbox>
                                <el-checkbox label="3">{{
                                    $t("SubmitWork.msg37")
                                }}</el-checkbox>
                                <el-checkbox label="4">{{
                                    $t("SubmitWork.msg38")
                                }}</el-checkbox>
                                <el-checkbox label="5">{{
                                    $t("SubmitWork.msg39")
                                }}</el-checkbox>
                                <el-checkbox label="6">{{
                                    $t("SubmitWork.msg40")
                                }}</el-checkbox>
                                <el-checkbox label="7">{{
                                    $t("SubmitWork.msg41")
                                }}</el-checkbox>
                                <el-checkbox label="8">{{
                                    $t("SubmitWork.msg42")
                                }}</el-checkbox>
                                <el-checkbox label="9">{{
                                    $t("SubmitWork.msg43")
                                }}</el-checkbox>
                                <el-checkbox label="10">{{
                                    $t("SubmitWork.msg44")
                                }}</el-checkbox>
                                <el-checkbox label="11">{{
                                    $t("SubmitWork.msg45")
                                }}</el-checkbox>
                                <el-checkbox label="12">{{
                                    $t("SubmitWork.msg46")
                                }}</el-checkbox>
                                <el-checkbox label="13">{{
                                    $t("SubmitWork.msg47")
                                }}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item :label="$t('SubmitWork.msg52')">
                            <el-image style="width: 100px; height: 100px" :src="form.annex" :preview-src-list="srcList">
                            </el-image>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item :label="$t('SubmitWork.msg52')">
                            <div style="display: inline-block;" v-for="(  item, index  ) in   bigfileList  " :key="index">
                                <el-image @click="checkbigimg(index)" style="width: 100px; height: 100px ;" :src="item">
                                </el-image>
                            </div>


                        </el-form-item>
                    </el-col>
                    <el-col :span="24" style="text-align: right;">
                        <el-button type="danger" @click="handklepass(0)">驳回</el-button>
                        <el-button type="primary" @click="handklepass(1)">通过</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <!-- <div slot="footer" class="dialog-footer">
                <el-button @click="open = false">关 闭</el-button>
            </div> -->
            <el-image-viewer style="z-index: 999999;" v-if="showViewer" :on-close="closeViewer" :url-list="bigfileList" />
        </el-dialog>
        <!-- <Footpage></Footpage> -->
    </div>
</template>
<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import {
    updatePwd, artlist, listUser, artadd
} from '@/api/index'
import { Message } from "element-ui";
export default {
    name: 'UploadDetails',
    components: {
        ElImageViewer, //注册
    },
    data() {
        return {
            bigfileList: [],
            showViewer: false,
            jobLogList: [],
            loading: true,
            form: {
                lengthval: undefined,
                widthval: undefined,
                heightval: undefined,
            },
            open: false,
            total: 0,
            srcList: [],
            userList: [],
            queryParams: {
                pageNum: 1,
                pageSize: 10,
            },
            activeName: 'first',
            fileList: [],
            artType: [],

        };
    },
    created() {
        this.getlistUser()
    },
    methods: {
        handklepass(v) {
            // artadd()
            this.form.draftFlag = v;
            this.form.artType = this.artType.join(',')
            this.form.pictures = this.bigfileList.join(',')
            console.log(this.form)
            artadd(this.form).then(res => {
                this.getuserDetail()
                this.open = false;
                this.$message({
                    type: 'success',
                    message: 'success'
                });
            })
        },
        handleClick(tab, event) {
            console.log(tab, event);
            this.queryParams.pageNum = 1
            if (tab.name == 'first') {
                this.getlistUser()
            } else {
                this.getuserDetail()
            }
        },
        logout() {
            // localStorage.removeItem('username')
            // localStorage.removeItem('password')
            localStorage.removeItem('token')
            this.$router.push({
                path: '/login'
            })
        },
        checkbigimg(idx) {
            this.bigfileList.unshift(...this.bigfileList.splice(idx));
            // this.open = false
            this.showViewer = true;
            // this.bigimg = url
        },
        closeViewer() {
            this.showViewer = false;
        },
        // 获取用户列表
        getlistUser() {
            listUser(this.queryParams).then(response => {
                console.log(response.data)
                this.userList = response.data.rows;
                this.total = response.data.total;
                this.loading = false;
                // this.form.email = response.data.data.email
            })
        },
        // 获取作品列表
        getuserDetail() {
            artlist(this.queryParams).then(response => {
                console.log(response.data)
                this.jobLogList = response.data.rows;
                response.data.rows.map(item => {
                    item.artType = item.artType.split(',')
                    item.pictures = item.pictures ? item.pictures.split(',') : []
                })
                this.total = response.data.total;
                this.loading = false;
                // this.form.email = response.data.data.email
            })
        },
        handleView(row) {
            this.open = true;
            this.form = row;
            const arr = row.size.split(',')
            console.log(arr)
            this.form.lengthval = arr[0]
            this.form.widthval = arr[1]
            this.form.heightval = arr[2]
            this.artType = row.artType
            this.form.artType = row.artType
            this.bigfileList = row.pictures
            this.srcList = [row.annex]
        },
    }
}
</script>
<style scoped>
.admindiv {
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 30px 0;
}
</style>