<template>
    <div class="alldiv">
        <div class="termscon">
            <p>{{ $t('terms.msg1') }}</p>
            <p>{{ $t('terms.msg2') }}</p>
            <p>{{ $t('terms.msg3') }}</p>
            <p>{{ $t('terms.msg4') }}</p>
            <p>{{ $t('terms.msg5') }}</p>
            <p>{{ $t('terms.msg6') }}</p>
            <p>{{ $t('terms.msg7') }}</p>
            <p>{{ $t('terms.msg8') }}</p>
            <p>{{ $t('terms.msg9') }}</p>
            <p>{{ $t('terms.msg10') }}</p>
            <p>{{ $t('terms.msg11') }}</p>
            <p>{{ $t('terms.msg12') }}</p>
            <p>{{ $t('terms.msg13') }}</p>
            <p>{{ $t('terms.msg14') }}</p>
            <p>{{ $t('terms.msg15') }}</p>
            <p>{{ $t('terms.msg16') }}</p>
            <p>{{ $t('terms.msg17') }}</p>
            <p>{{ $t('terms.msg18') }}</p>
            <p>{{ $t('terms.msg19') }}</p>
            <p>{{ $t('terms.msg20') }}</p>
            <p>{{ $t('terms.msg21') }}</p>
            <p>{{ $t('terms.msg22') }}</p>
            <p>{{ $t('terms.msg23') }}</p>
            <p>{{ $t('terms.msg24') }}</p>
            <p>{{ $t('terms.msg25') }}</p>
            <p>{{ $t('terms.msg26') }}</p>
            <p>{{ $t('terms.msg27') }}</p>
            <p>{{ $t('terms.msg28') }}</p>
            <p>{{ $t('terms.msg29') }}</p>
            <p>{{ $t('terms.msg30') }}</p>
            <p>{{ $t('terms.msg31') }}</p>
            <p>{{ $t('terms.msg32') }}</p>
            <p>{{ $t('terms.msg33') }}</p>
            <p>{{ $t('terms.msg34') }}</p>
            <p>{{ $t('terms.msg35') }}</p>
            <p>{{ $t('terms.msg36') }}<a data-auto-recognition="true" href="mailto:arts@ruthineducation.co.uk"
                    class="wixui-rich-text__text email">arts@ruthineducation.co.uk</a></p>
            <!-- <p></p> -->
            <p>{{ $t('terms.msg37') }}</p>
        </div>

    </div>
</template>
<script>
export default {
    name: 'Terms',
    data() {
        return {
            msg: '头部'
        };
    },
    methods: {

    }
}
</script>
<style scoped>
.alldiv {
    background: #f7f7f7;
}

.termscon {
    box-sizing: border-box;
    padding: 40px;
    width: 900px;
    margin: 0 auto;
    text-align: left;
    background: #fff;
}

.termscon p {
    line-height: 40px;
}</style>